import { Badge, Button, Card, CardActionArea, CardContent, CardMedia, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { IProductPreview } from '../../api/interfaces/interfaces';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { useMediaQuery } from '@mui/material';
import { ensureLocation } from '../../functions/CommonFunctions';
import { useNavigate } from 'react-router-dom';

interface ProductListCarouselProps {
	productList: IProductPreview[];
	// handleClickOnProduct: (id: UUID) => void;
}

export const ProductListCarousel = (props: ProductListCarouselProps) => {
	const { productList } = props;
	const [currentIndex, setCurrentIndex] = useState(0);
	const isMobileScreen = useMediaQuery('(max-width:1000px)');
	const navigate = useNavigate();
	const handleNext = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex < productList.length - 1 ? prevIndex + 1 : 0));
	}, [productList]);

	const handleBack = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : productList.length - 1));
	}, [productList]);

	function currencyFormatter(value: number) {
		return new Intl.NumberFormat('es-AR', {}).format(value);
	};

	const theme = createTheme({
		palette: {
		  primary: {
			main: '#3F51B5',
			contrastText: '#fffff4',
		  },
		  secondary: {
			main: '#3F51B5',
			contrastText: '#fffff4',
		  },
		},
	  });

	  	/// funcion para cuando el cliente ingrese productos SOLO EN MAYUSCULAS
	const formatWord = (word: string) => {
		const hasMultipleUppercase = word.split('').filter(char => char === char.toUpperCase()).length > 1;
		if (hasMultipleUppercase) {
		  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		}
		return word;
	  };
	  
	  // Función para formatear todo el texto
	  const formatText = (text: string | undefined) => {
		if (!text) return '';
		return text
		  .split(' ')
		  .map(formatWord)
		  .join(' ');
	  }; 

	return (
		<Grid container direction="row" justifyContent="space-between">
			<Grid container direction="row" spacing=".8rem" marginBottom="2rem" marginTop="2rem" >

				<Button
					sx={{
						color: 'white',
						marginBottom: '5px',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: 'fit-content',
						marginTop: { xs: '30%', md: '8%' },
					}}
					size="small"
					onClick={handleBack}>
					<KeyboardArrowLeft sx={{ fontSize: '40px' }} />
				</Button>
				{productList &&
					productList.map(
						(product, index) =>
							index < 4 &&
							(!isMobileScreen || index === 0) && (
								<Grid key={index} marginLeft={index > 0 && !isMobileScreen ? '10px' : '0'} item>
									<Card
										sx={{
											width: { md: '13vw', sm: '60vw', xs: '60vw' },
											backgroundColor: '#111111',
											height: 'auto',
											maxWidth: 250,
											boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
											marginTop: '10%',
											transition: 'transform 0.3s ease',
											'&:hover': {
												transform: 'translateY(-5px)',
											},
											'& .card-header': {
												padding: '20px',
												borderTopLeftRadius: '10px',
												borderTopRightRadius: '10px',
												backgroundColor: '#111111',
												color: '#fff',
											},
										}}
										onClick={() => navigate(`/product-detail/${(productList[(currentIndex + index) % productList.length].id)}`)}>
										<CardActionArea>
											{productList[(currentIndex + index) % productList.length].images.length > 0 && (
												<CardMedia
													sx={{
														height: '24vh',
														padding: '3%',
														backgroundColor: '#111111',
														borderRadius: '1rem',
													}}
													component="img"
													image={productList[(currentIndex + index) % productList.length].images[0].imageData!}
													alt={productList[(currentIndex + index) % productList.length].name}
												/>
											)}
											<CardContent
												sx={{
													backgroundColor: '#111111',
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
													padding: '.6rem',
													height: '13vh'
												}}>
												<Grid container direction="column" spacing={0.1}>
													<Grid item>
														<Typography
															sx={{
																fontFamily: 'Roboto',
																fontWeight: 'bold',
																fontSize: '13px',
																backgroundColor: '#111111',
																color: 'white',
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
															}}>
															{formatText(productList[(currentIndex + index) % productList.length].name)}
														</Typography>
													</Grid>
													<Grid item sx={{ display: 'flex' }}>
														<Typography color="white" sx={{fontSize: '13px'}}>
															<AttachMoneyIcon
																style={{
																	fontSize: '1rem',
																	alignItems: 'center',
																	verticalAlign: 'middle',
																	color: 'white',
																	marginBottom: '.1rem',
																	marginLeft: '-.3rem',
																}}
															/>
															{currencyFormatter(productList[(currentIndex + index) % productList.length].price)}
														</Typography>
													</Grid>
													<Grid item sx={{ display: 'flex' }}>
														<Typography fontSize="12px" color="white" whiteSpace="nowrap" overflow='hidden' textOverflow='ellipsis'>
															{ensureLocation(
																productList[(currentIndex + index) % productList.length].city!,
																productList[(currentIndex + index) % productList.length].state!,
															)}
														</Typography>
													</Grid>
													<Grid item sx={{ display: 'flex', justifyContent: 'baseline', mt: '.8rem', ml: '4.4rem' }}>
													<ThemeProvider theme={theme}>
														<Badge
															color={product.condition === 'Agregado reciente' ? 'primary' : 'secondary'}
															badgeContent="Agregado recientemente"
															sx={{ whiteSpace: 'nowrap' }}
														/>
													</ThemeProvider>
													</Grid>

												</Grid>
											</CardContent>
										</CardActionArea>
									</Card>
								</Grid>
							),
					)}
				<Button
					size="small"
					onClick={handleNext}
					sx={{
						color: 'white',
						marginBottom: '5px',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: 'fit-content',
						marginTop: { xs: '30%', md: '8%' },
					}}>
					<KeyboardArrowRight sx={{ fontSize: '40px' }} />
				</Button>
			</Grid>
		</Grid>
	);
};
