import { Grid, TextField } from "@mui/material";
import {
  IImage,
  INewProduct,
} from "../../../../api/interfaces/interfaces";
import styled from '@emotion/styled';

interface StepOneProps {
  handleChangeForm: (
    fieldName: keyof INewProduct,
    value: string | number | IImage[] | null
  ) => void;
  newProduct: INewProduct;
  // editedProductId: string;
}

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputLabel-root': {
		color: 'white',
		'&.Mui-focused': {
			color: 'white',
		},
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: 'black',
		color: 'white',
		'& fieldset': {
			borderColor: '#9e1616',
		},
		'&:hover fieldset': {
			borderColor: '#9e1616',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#9e1616',
		},
	},
}));

export const StepOne = (props: StepOneProps) => {
  const { handleChangeForm, newProduct } = props;

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\D/g, ""); 
    handleChangeForm("price", rawValue ? parseInt(rawValue, 10) : 0);
  };

  const formatNumber = (num: number) => {
    if (num === 0) return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          mt: 5,
          width: { xs: "90%", sm: "80%", md: "100%", xl: "100%" },
          margin: "auto",
        }}
      >
        <StyledTextField
          label="Nombre del repuesto *"
          type="text"
          fullWidth
          name="productName"
          value={newProduct.name}
          onChange={(event) => handleChangeForm("name", event.target.value)}
          InputProps={{ style: { borderRadius: '10px' } }}
          sx={{
            marginTop: "2%",
          }}
        //disabled={isLoading}
        />
        <StyledTextField
          label="Descripción del repuesto *"
          type="text"
          fullWidth
          multiline
          rows={3}
          name="productDescription"
          value={newProduct.description}
          onChange={(event) =>
            handleChangeForm("description", event.target.value)
          }
          InputProps={{ style: { borderRadius: '10px' } }}
          sx={{
            borderRadius: 3,
            // backgroundColor: "#eef3f8",
            marginTop: "2%",
            width: "10s0%",
          }}
        // disabled={isLoading}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: 2,
          marginBottom: 10,
          width: { xs: "90%", sm: "80%", md: "100%", xl: "100%" },
          margin: "auto",
        }}
      >
        <StyledTextField
          label="Precio del repuesto *"
          type="text"
          fullWidth
          name="price"
          value={newProduct.price !== 0 ? formatNumber(newProduct.price) : ""}
          onChange={handlePriceChange}
          inputProps={{ min: 0, maxLength: 10 }}
          InputProps={{ style: { borderRadius: '10px' } }}
          sx={{
            borderRadius: 3,
            // backgroundColor: "#eef3f8",
            marginTop: "2%",
            width: "100%",
          }}
        // disabled={isLoading}
        />
      </Grid>
    </>
  );
};
