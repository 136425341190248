import React from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { CreateAccidentData, Insured, InsuredVehicle } from '../../../../../api/interfaces/interfaces';
import styled from '@emotion/styled';

interface AccidentInfoStepProps {
  formData: CreateAccidentData;
  errors: { [key: string]: string };
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchInsured: (value: string) => void;
  handleInsuredChange: (value: any) => void;
  insured: Insured[];
  vehicles: InsuredVehicle[];
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}


const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputLabel-root': {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'black',
    color: 'white',
    '& fieldset': {
      borderColor: '#9e1616',
    },
    '&:hover fieldset': {
      borderColor: '#9e1616',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#9e1616',
    },
  },
}));

export const AccidentInfoStep: React.FC<AccidentInfoStepProps> = ({
  formData,
  errors,
  handleInputChange,
  searchInsured,
  handleInsuredChange,
  insured,
  vehicles,
  setFormData,
}) => {
  return (
    <Grid container spacing={2} sx={{ flex: 1 }}>
      <Grid item xs={6}>
        <StyledTextField
          size="small"
          fullWidth
          label="Número de denuncia"
          name="accidentNumber"
          value={formData.accidentNumber}
          onChange={handleInputChange}
          error={!!errors.nroDenuncia}
          helperText={errors.nroDenuncia}
          InputProps={{ style: { backgroundColor: 'black', color: 'white' } }}
          InputLabelProps={{ style: { color: 'white' } }}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          fullWidth
          type="date"
          label="Fecha del siniestro"
          size="small"
          name="accidentDate"
          value={formData.accidentDate}
          onChange={handleInputChange}
          error={!!errors.accidentDate}
          helperText={errors.accidentDate}
          InputLabelProps={{ shrink: true, style: { color: 'white' } }}
          InputProps={{ style: { backgroundColor: 'black', color: 'white' } }}
        />
      </Grid>
      <Grid item xs={6} >
        <Autocomplete
          disablePortal
          size='small'
          onInputChange={(e, value) => value.length >= 3 && searchInsured(value)}
          onChange={(e, value) => value && handleInsuredChange(value)}
          options={insured}
          getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
          sx={{ width: 280, backgroundColor: 'white' }}
          renderInput={(params) => <StyledTextField {...params} label="Asegurado" />}
        />
      </Grid>
      {vehicles && vehicles.length > 0 && (
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            size='small'
            onChange={(e, value) =>
              setFormData((prev: any) => ({ ...prev, insuredVehicleId: value?.id || '' }))
            }
            options={vehicles}
            getOptionLabel={(option) =>
              `${option.vehicleBrand} ${option.vehicleModel} | ${option.plateNumber}`
            }
            sx={{ width: 280, backgroundColor: 'white' }}
            renderInput={(params) => <StyledTextField {...params} label="Vehículo" />}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <StyledTextField
          size="small"
          fullWidth
          multiline
          rows={4}
          label="Descripción"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          error={!!errors.descripcion}
          helperText={errors.descripcion}
          InputProps={{ style: { backgroundColor: 'black', color: 'white' } }}
          InputLabelProps={{ style: { color: 'white' } }}
        />
      </Grid>
    </Grid>
  );
};
