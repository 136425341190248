import { useState, useEffect } from 'react';
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	CardMedia,
	IconButton,
	Dialog,
	DialogContent,
	DialogActions,
	Card,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from '@emotion/styled';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SwipeableViews from 'react-swipeable-views';
import {
	BillingMail,
	Accident,
	InsuranceCompany,
	InsuredVehicle,
	Insured,
	DocumentImage,
	Attachment,
} from '../../../../api/interfaces/interfaces';
import {
	billAccident,
	getAccidentById,
	getInsuranceCompanyDataByName,
	getInsuredVehicle,
	getInsuredById,
} from '../../../../api/insuredApi';
import { getAllAccidentDocumentsByOwner, getAllVehicleDocumentsByOwner } from '../../../../api/documentsApi';

interface CreateBillingStepperProps {
	accidentId: string; // id del accidente
	handleClose: () => void;
	// setBillingList: React.Dispatch<React.SetStateAction<BillingMail[]>>;
}

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputLabel-root': {
		color: 'white',
		'&.Mui-focused': { color: 'white' },
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: 'black',
		color: 'white',
		'& fieldset': { borderColor: '#9e1616' },
		'&:hover fieldset': { borderColor: '#9e1616' },
		'&.Mui-focused fieldset': { borderColor: '#9e1616' },
	},
}));

export const CreateBillingStepper = (props: CreateBillingStepperProps) => {
	const { accidentId, handleClose } = props;
	const [accidentData, setAccidentData] = useState<Accident | null>(null);
	const [vehicleData, setVehicleData] = useState<InsuredVehicle | null>(null);
	const [insuranceCompanyData, setInsuranceCompanyData] = useState<InsuranceCompany | null>(null);
	const [insuredData, setInsuredData] = useState<Insured | null>(null);
	const [documents, setDocuments] = useState<DocumentImage[]>([]);

	const [selectedCompany, setSelectedCompany] = useState<string>('');
	const [toEmail, setToEmail] = useState<string>('');
	const [subject, setSubject] = useState<string>('');
	const [emailBody, setEmailBody] = useState<string>('');

	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const [openPreview, setOpenPreview] = useState<boolean>(false);

	const [slideIndex, setSlideIndex] = useState<number>(0);
	const [showArrows, setShowArrows] = useState(false);

	const loadAccidentData = async (accidentId: string): Promise<Accident | null> => {
		const accident = await getAccidentById(accidentId);
		if (accident) {
			setAccidentData(accident);
			setSubject(`Facturación siniestro Nº ${accident.accidentNumber}`);
			return accident;
		}
		return null;
	};

	const loadVehicleData = async (vehicleId: string): Promise<InsuredVehicle | null> => {
		const vehicle = await getInsuredVehicle(vehicleId);
		if (vehicle) {
			setVehicleData(vehicle);
			return vehicle;
		}
		return null;
	};

	const loadCompanyData = async (companyName: string): Promise<void> => {
		const company = await getInsuranceCompanyDataByName(companyName);
		if (company) {
			setInsuranceCompanyData(company);
			setSelectedCompany(company.companyName);
			setToEmail(company.email);
			setEmailBody(company.emailModelText);
		}
	};

	const firstLoad = async (accId: string) => {
		if (!accId) return;
		const accData = await loadAccidentData(accId);
		if (accData && accData.insuredVehicleId) {
			const vehData = await loadVehicleData(accData.insuredVehicleId);
			if (vehData && vehData.insuranceProduct && vehData.insuranceProduct.productCompany) {
				await loadCompanyData(vehData.insuranceProduct.productCompany);
			}
		}
	};

	useEffect(() => {
		if (accidentData?.insured) {
			getInsuredById(accidentData.insured).then((data) => {
				if (data) setInsuredData(data);
			});
		}
	}, [accidentData]);

	useEffect(() => {
		if (accidentId) {
			firstLoad(accidentId);
		}
	}, [accidentId]);

	const loadDocuments = async () => {
		if (accidentId) {
			const docs = await getAllAccidentDocumentsByOwner(accidentId);
			setDocuments(docs);
		}
	};

	useEffect(() => {
		if (vehicleData?.id) {
			loadDocuments();
		}
	}, [vehicleData]);

	const handlePreview = (imageData: string) => {
		setPreviewImage(imageData);
		setOpenPreview(true);
	};

	const handleDeleteDocument = (docId: string) => {
		if (window.confirm('¿Está seguro de eliminar este documento?')) {
			setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== docId));
		}
	};

	const sendBillAccident = async () => {
		if (!selectedCompany || !toEmail || !subject) {
			window.alert('Faltan datos necesarios para facturar');
			return;
		}
		if (!accidentData || !insuranceCompanyData) {
			window.alert('No se pudo cargar la información necesaria.');
			return;
		}
		let attachments: Attachment[] = [];
		documents.forEach((doc, index) => {
			attachments[index] = {
				content: doc.imageData,
				contentType: doc.mimeType,
				filename: doc.name,
			};
		});
		const bill: BillingMail = {
			accidentId: accidentData.id,
			emailBody: insuranceCompanyData.emailModelText,
			employeeName: insuranceCompanyData.employeeName,
			subject: subject,
			to: toEmail,
			attachments: attachments,
		};
		await billAccident(bill);
		window.alert('Siniestro facturado correctamente.');
		// setBillingList((prev) => [...prev, bill]);
		handleClose();
	};

	return (
		<Box
			sx={{
				border: '1px solid white',
				borderRadius: 2,
				p: 3,
				mx: 'auto',
				position: 'relative',
				minWidth: 750,
				maxWidth: 750,
			}}>
			<SwipeableViews index={slideIndex} onChangeIndex={(index) => setSlideIndex(index)} enableMouseEvents>
				<Box sx={{ p: 2 }}>
					<Typography variant="h6" sx={{ textAlign: 'center', mb: 5, color: 'white', fontWeight: 300 }}>
						Información del siniestro
					</Typography>
					<Typography variant="body1" sx={{ mb: 3 }}>
						<strong>Nombre de asegurado:</strong>{' '}
						{insuredData ? `${insuredData.firstname} ${insuredData.lastname}` : 'No cargado'}
					</Typography>
					<Typography variant="body1" sx={{ mb: 3 }}>
						<strong>DNI de asegurado:</strong> no lo tengo en este scope
					</Typography>
					<Typography variant="body1" sx={{ mb: 3 }}>
						<strong>Vehículo:</strong>{' '}
						{vehicleData &&
							`${vehicleData.vehicleBrand} ${vehicleData.vehicleModel} ${vehicleData.vehicleYear} ${vehicleData.plateNumber}`}
					</Typography>
					<Typography variant="body1" sx={{ mb: 3 }}>
						<strong>Fecha:</strong> {accidentData ? new Date(accidentData.accidentDate).toLocaleDateString() : ''}
					</Typography>
					<Typography variant="body1">
						<strong>Descripción:</strong> {accidentData?.description}
					</Typography>
				</Box>

				<Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
					<Typography variant="h6" sx={{ textAlign: 'center', mb: 1, color: 'white', fontWeight: 300 }}>
						Datos de facturación
					</Typography>
					<Box sx={{ mb: 2, mt: 2 }}>
						<FormControl sx={{ width: 150, mr: 2 }}>
							<InputLabel id="company-select-label" sx={{ color: 'white' }}>
								Compañía a facturar
							</InputLabel>
							<Select
								labelId="company-select-label"
								value={selectedCompany}
								label="Compañía a facturar"
								size="small"
								onChange={(e) => setSelectedCompany(e.target.value)}
								sx={{
									color: 'white',
									backgroundColor: 'black',
									border: '1px solid #9e1616',
									'.MuiSelect-icon': { color: 'white' },
								}}
								MenuProps={{
									PaperProps: {
										sx: {
											backgroundColor: 'black',
											'& .MuiMenuItem-root': { color: 'white', '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.3)' } },
										},
									},
								}}>
								{insuranceCompanyData && (
									<MenuItem value={insuranceCompanyData.companyName}>{insuranceCompanyData.companyName}</MenuItem>
								)}
							</Select>
						</FormControl>
						<StyledTextField
							label="Para"
							size="small"
							value={toEmail}
							onChange={(e) => setToEmail(e.target.value)}
							fullWidth
							sx={{ maxWidth: 250 }}
						/>
					</Box>
					<StyledTextField
						size="small"
						label="Asunto"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						fullWidth
						sx={{ mb: 5, maxWidth: 300 }}
					/>
					<StyledTextField
						size="small"
						label="Modelo de estructura de email a enviar"
						value={emailBody}
						onChange={(e) => setEmailBody(e.target.value)}
						fullWidth
						multiline
						rows={5}
						sx={{ maxWidth: 800 }}
					/>
				</Box>

				<Box sx={{ borderRadius: 2 }}>
					<Typography variant="h6" sx={{ textAlign: 'center', mb: 2, mt: 2, color: 'white', fontWeight: 300 }}>
						Documentación a adjuntar
					</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'repeat(3, 1fr)',
							gap: 1,
							justifyContent: 'center',
							alignItems: 'flex-start',
						}}>
						{documents.map((doc) => (
							<Box key={doc.id} sx={{ position: 'relative', width: 130, justifySelf: 'center' }}>
								{doc.mimeType.includes('image/') ? (
									<CardMedia
										component="img"
										height="110"
										src={doc.imageData!}
										alt="Documento"
										onClick={() => handlePreview(doc.imageData!)}
										sx={{
											borderRadius: '1.2rem',
											boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
											backgroundColor: '#111111',
											cursor: 'pointer',
											padding: 1,
										}}
									/>
								) : (
									<Card>
										{doc.name}
										<PictureAsPdfIcon fontSize="large" />
									</Card>
								)}
								<Typography variant="caption" sx={{ color: 'white', display: 'block', textAlign: 'center', mt: 1 }}>
									{doc.documentReference}
								</Typography>
								<IconButton
									onClick={() => handleDeleteDocument(doc.id)}
									sx={{
										position: 'absolute',
										top: 8,
										right: -20,
										color: '#b8a6a6',
										width: 30,
										height: 30,
									}}>
									<DeleteIcon fontSize="small" />
								</IconButton>
							</Box>
						))}
					</Box>
				</Box>
			</SwipeableViews>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					mt: 2,
					position: 'relative',
					width: '100%',
				}}
				onMouseEnter={() => setShowArrows(true)}
				onMouseLeave={() => setShowArrows(false)}>
				<IconButton
					onClick={() => setSlideIndex((prev) => (prev > 0 ? prev - 1 : 0))}
					sx={{
						color: 'white',
						opacity: showArrows ? 1 : 0,
						transition: 'opacity 0.3s ease',
					}}>
					<ArrowBackIosIcon />
				</IconButton>

				<Typography variant="body2" sx={{ color: 'white' }}>
					{slideIndex + 1} / 3
				</Typography>

				<IconButton
					onClick={() => setSlideIndex((prev) => (prev < 2 ? prev + 1 : 2))}
					sx={{
						color: 'white',
						opacity: showArrows ? 1 : 0,
						transition: 'opacity 0.3s ease',
					}}>
					<ArrowForwardIosIcon />
				</IconButton>
			</Box>

			<Box
				sx={{
					mt: 3,
					display: 'flex',
					justifyContent: 'flex-end',
					gap: 2,
				}}>
				<Button
					size="small"
					variant="contained"
					sx={{
						backgroundColor: '#a70d15',
						color: 'white',
						boxShadow: '0px 4px 8px rgba(0,0,0,0.3)',
						'&:hover': { backgroundColor: '#8a1218' },
					}}
					onClick={handleClose}>
					Cancelar
				</Button>
				<Button
					variant="contained"
					size="small"
					sx={{
						backgroundColor: '#a70d15',
						boxShadow: '0px 4px 8px rgba(0,0,0,0.3)',
						'&:hover': { backgroundColor: '#8a1218' },
					}}
					onClick={sendBillAccident}>
					Enviar facturación
				</Button>
			</Box>

			<Dialog open={openPreview} onClose={() => setOpenPreview(false)} maxWidth="lg" sx={{ backgroundColor: 'black' }}>
				<DialogContent>
					{previewImage && (
						<img src={previewImage} alt="Preview" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenPreview(false)}>Cerrar</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

// "Factura"
// "Orden de reparación"
// "Orden de compra"
// "Tarjeta verde"
// "Vehículo"
// "Otros"
