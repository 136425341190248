import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material'
import { Accident } from '../../../../api/interfaces/interfaces';

const StatsBoxStyles = styled(Box)({
    backgroundColor: 'black',
    border: '1px solid #a70d15',
    padding: 20,
    borderRadius: 10,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
});


interface StatsBoxProps {
    filteredAccidents: Accident[];
}


export const StatsBox = (props: StatsBoxProps) => {
    const { filteredAccidents } = props;
    
    return (
        <>
            <Grid item xs={12} sm={6}>
                <StatsBoxStyles>
                    <Typography variant="h3" color="white">
                        {
                            filteredAccidents.filter((accident) => accident.billingStatus === "en proceso")
                                .length
                        }
                    </Typography>
                    <Typography variant="body1" color="white">
                        Siniestros pendientes
                    </Typography>
                </StatsBoxStyles>
            </Grid>
            <Grid item xs={12} sm={6}>
                <StatsBoxStyles>
                    <Typography variant="h3" color="white">
                        {filteredAccidents.length}
                    </Typography>
                    <Typography variant="body1" color="white">
                    Siniestros totales
                    </Typography>
                </StatsBoxStyles>
            </Grid>
        </>
    )
}
