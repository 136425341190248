import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ICommentWithoutResponse } from '../../../api/interfaces/interfaces';
import { getCommetsWithoutResponseByProductOwner, sendCommentResponse } from '../../../api/commentsApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommentCard } from './CommentCard';
import { NoCommentAvailable } from './NoCommentAvailable';

export const CommentManagement = () => {
	const [comments, setComments] = useState<ICommentWithoutResponse[]>();
	const [isFetchingComments, setIsFetchingComments] = useState(false);
	// const [isSendingResponse, setIsSendingResponse] = useState(false);
	const navigate = useNavigate();
	async function getComments() {
		setIsFetchingComments(true);
		const comments = await getCommetsWithoutResponseByProductOwner();
		if (comments.length > 0) {
			setComments(comments);
		}
		setIsFetchingComments(false);
	}
	const [sendingResponseStates, setSendingResponseStates] = useState<{ [key: string]: boolean }>({});
	const onSendResponse = async (commentID: string, comment: string) => {
		setSendingResponseStates((prev) => ({ ...prev, [commentID]: true }));
		const rta = await sendCommentResponse(commentID, comment);
		setSendingResponseStates((prev) => ({ ...prev, [commentID]: false }));
		if (rta.status === 200) {
			const updatedComments = await getCommetsWithoutResponseByProductOwner();
			setComments(updatedComments);
		}
	};
	useEffect(() => {
		const getComments = async () => {
			setIsFetchingComments(true);
			const commentsData = await getCommetsWithoutResponseByProductOwner();
			setComments(commentsData);
			setIsFetchingComments(false);
		};
		getComments();
	}, []);


	// const onSendResponse = async (commentID: string, comment: string) => {
	// 	setIsSendingResponse(true);
	// 	const rta = await sendCommentResponse(commentID, comment);
	// 	setIsSendingResponse(false);
	// 	if (rta.status === 200) {
	// 		const comments = await getCommetsWithoutResponseByProductOwner();
	// 		setComments(comments);
	// 	}
	// };
	// useEffect(() => {
	// 	getComments();
	// }, []);

	return (
		<Grid
			container
			spacing={8}
			alignItems="center"
			justifyContent="center"
			sx={{ minHeight: '100vh', backgroundColor: '#111111' }}>
			<Grid item xs={12} sm={8} md={6} lg={7}>
				<Card
					style={{
						backgroundColor: '#111111',
						minHeight: '70vh',
						maxHeight: '70vh',
						textAlign: 'center',
						overflow: 'auto',
						borderTop: 'thin solid #717171',
						borderRadius: '26px',
						borderLeft: 'thin solid #717171',
						borderRight: 'thin solid #717171',
						borderBottom: 'thin solid #717171',
					}}>
					<CardContent>
						<Card
							style={{
								backgroundColor: '#111111',
								marginBottom: '1rem',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								position: 'sticky',
								zIndex: '90',
								top: 0,
							}}>
							<Typography
								sx={{
									marginTop: '.5rem',
									color: '#fffff4',
									fontFamily: 'arial',
									fontSize: { md: '1.5rem' },
								}}
								gutterBottom>
								Preguntas en tus publicaciones
							</Typography>
							{/* <Button
								// onClick={() => {
								// 	handleShowProducts();
								// }}
								sx={{
									borderBottom: 'thin solid red',
									'&:hover': { backgroundColor: '#71717171' },
									height: { xs: 'auto', sm: 'auto', md: '1.5rem' },
									fontSize: { xs: '.5rem', md: '.7rem' },
									backgroundColor: '#111111',
								}}
								size="small"
								variant="contained">
								boton sin usar
							</Button> */}
						</Card>
						{isFetchingComments && (
							<Grid
								style={{
									position: 'fixed',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
								}}>
								<CircularProgress size={60} color="error" />
							</Grid>
						)}
						{!isFetchingComments && comments && (
							<Grid container spacing={2}>
								{comments?.map((comment, index) => (
									<Grid key={'index'} item xs={12} sx={{ padding: '0.1rem', height: 'fit-content' }}>
										<Card
											sx={{
												marginBottom: '.5rem',
												height: '16vh',
												backgroundColor: '#111111',
												// borderBottom: 'thin dotted red',
												display: 'flex',
												alignItems: 'center',
												padding: '0.5rem',
												position: 'relative', // Añadido para posicionar correctamente el botón
											}}>
											{/* Imagen con border radius */}
											<Button
												onClick={() => navigate(`/product-detail/${comment.productID}`)}
												sx={{ width: { xs: '100%', md: '45%', lg: 'fit-content' }, justifyContent: 'left' }}>
												<Grid
													sx={{
														borderRadius: '22%',
														overflow: 'hidden',
														maxWidth: '30%',
														maxHeight: '100%',
														marginRight: '0.5rem',
													}}>
													<Box
														component='img'
														src={comment.productImageData}
														alt="Imagen"
														sx={{ width: '100%', height: '100%', padding: { xs: 0, sm: 1 }, objectFit: 'cover' }}
													/>
												</Grid>
												<Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
													<Typography
														sx={{
															color: 'white',
															marginTop: '.6rem',
															marginBottom: '.5rem',
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															fontFamily: 'arial',
															fontSize: '.8rem',
															textAlign: 'left',
															width: '100%',
														}}
														key={index + 'ty2'}
														variant="button">
														{comment.productName}
													</Typography>
													<Typography
														sx={{
															textAlign: 'left',
															color: 'white',
															fontFamily: 'arial',
															marginBottom: '.5rem',
															fontSize: '.8rem',
														}}
														variant="button"
														key={index + 'ty3'}>
														{comment.productPrice}
													</Typography>
													<Typography
														sx={{
															width: { xs: '55%', md: '100%' },
															color: 'white',
															fontFamily: 'arial',
															marginBottom: '.5rem',
															fontSize: '.8rem',
															textAlign: 'left',
														}}
														key={index + 'ty4'}
														variant="button">
														{comment.productCondition}
													</Typography>
												</Grid>
											</Button>
										</Card>
										<Accordion sx={{ backgroundColor: '#111', borderBottom: 'thin solid red' }}>
											<AccordionSummary
												sx={{ color: 'white', backgroundColor: '#111' }}
												expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
												aria-controls="panel1-content"
												id="panel1-header">
												Preguntas
											</AccordionSummary>
											<AccordionDetails>
												<Card sx={{ backgroundColor: '#111' }}>
													<Grid container xs={12} sx={{ backgroundColor: '#111', display: 'flex', justifyContent: 'center' }}>
														{comment.asks?.map((ask, index) => (
															<Grid
																item
																xs={12}
																//  xl={index === 0 ? 12 : 5.8} // Aplicamos xs={12} al primer elemento y xs={5.5} al resto
																xl={5.8}
																sx={{ marginBottom: '.5rem', marginRight: '.5rem', backgroundColor: '#131313', borderRadius: '17px' }}
																key={ask.commentID}
															>
																<CommentCard
																	key={ask.commentID}
																	ask={ask}
																	isSendingResponse={sendingResponseStates[ask.commentID] || false}
																	sendResponse={onSendResponse}

																/>
															</Grid>
														))}
													</Grid>
												</Card>
											</AccordionDetails>
										</Accordion>
									</Grid>
								))}
							</Grid>
						)}
						{!isFetchingComments && comments && comments.length === 0 && (
							<NoCommentAvailable />
						)}
					</CardContent>
				</Card>
			</Grid>
			{/* {alert.show && <CustomAlert message={alert.message} type={alert.type} setAlert={setAlert} />} */}
		</Grid>
	);
};
