import styled from "@emotion/styled";
import { InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Accident } from "../../../../api/interfaces/interfaces";
import { useCallback, useEffect, useState } from "react";
import { getAccidentById } from "../../../../api/insuredApi";


const SearchBoxStyles = styled(Paper)({
    display: "flex",
    width: "40%",
    alignItems: "center",
    marginBottom: 16,
    padding: "4px 16px",
    borderRadius: 20,
    border: "1px solid #cf1717",
});

interface SearchBoxProps {
    accidentList: Accident[];
    setAccidentList: React.Dispatch<React.SetStateAction<Accident[]>>;
}

export const SearchBox = (props: SearchBoxProps) => {
    // const { accidentList, setAccidentList } = props;
    // const [accident, setAccident] = useState<Accident | null>(null);
    // const [accidentSearch, setAccidentSearch] = useState('');

    // const debounceAccidentSearch = useCallback(() => {
    //     const handler = setTimeout(async () => {
    //         if (accidentSearch.trim()) {
    //             const data = await getAccidentById(accidentSearch.trim());      ESPERANDO A QUE FUNCIONE EL BUSCADOR
    //             setAccident(data);
    //         } else {
    //             setAccident(null);
    //         }
    //     }, 300);

    //     return () => clearTimeout(handler);
    // }, [accidentSearch]);

    // useEffect(() => {
    //     debounceAccidentSearch();
    // }, [accidentSearch, debounceAccidentSearch]);

    return (
        <>
            <SearchBoxStyles style={{ backgroundColor: 'black' }}>
                <InputBase
                    style={{ backgroundColor: 'black', color: 'white' }}
                    placeholder="nro siniestro, nombre, apellido, vehiculo"
                    fullWidth
                    // value={accidentSearch}
                    // onChange={(e) => setAccidentSearch(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: 'white' }} />
                        </InputAdornment>
                    }
                />
            </SearchBoxStyles></>
    )
}
