import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography } from '@mui/material';
import { Accident } from '../../../../api/interfaces/interfaces';
import { useState } from 'react';
import { CreateBillingStepper } from '../billing/CreateBillingStepper';

interface AccidentDetailsProps {
	openModal: boolean;
	handleCloseModal: () => void;
	selectedAccident: Accident | null;
}
const modalStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 780,
	bgcolor: 'black',
	color: 'white',
	boxShadow: 24,
	p: 2,
	borderRadius: 2,
};
export const AccidentDetails = (props: AccidentDetailsProps) => {
	const { openModal, handleCloseModal, selectedAccident } = props;
	const [showBill, setShowBill] = useState<boolean>(false);
	return (
		<Dialog open={openModal} onClose={handleCloseModal}>
			{!showBill ? (
				<Box sx={{ backgroundColor: 'black', color: 'white', width: 500, border: '1px solid #a70d15' }}>
					<DialogTitle>Detalles del Siniestro</DialogTitle>
					<DialogContent>
						{selectedAccident && (
							<>
								<div>
									<strong>Número de Siniestro:</strong> {selectedAccident.accidentNumber}
								</div>
								<div>
									<strong>Fecha:</strong> {new Date(selectedAccident.createdAt).toLocaleDateString()}
								</div>
								<div>
									<strong>Descripción:</strong> {selectedAccident.description}
								</div>
								<div>
									<strong>Estado:</strong> {selectedAccident.status}
								</div>
								<div>
									<strong>Asegurado:</strong> {selectedAccident.insured}
								</div>
								{/* <div><strong>Denuncia:</strong> {selectedAccident.files?.accident}</div>
                            <div><strong>Orden de reparación:</strong> {selectedAccident.files?.repairOrder}</div>
                            <div><strong>Fotos del vehículo:</strong> {selectedAccident.files?.photos}</div> */}
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleCloseModal}
							sx={{
								backgroundColor: '#a70d15',
								color: 'white',
								marginRight: 4,
								marginBottom: 2,
								'&:hover': {
									backgroundColor: '#8a1218',
									borderColor: '#8a1218',
								},
							}}>
							Cerrar
						</Button>
						<Button
							onClick={() => setShowBill(true)}
							sx={{
								backgroundColor: '#a70d15',
								color: 'white',
								marginRight: 4,
								marginBottom: 2,
								'&:hover': {
									backgroundColor: '#8a1218',
									borderColor: '#8a1218',
								},
							}}>
							Facturar
						</Button>
					</DialogActions>
				</Box>
			) : (
				<Modal open={showBill} onClose={setShowBill}>
					<Box sx={modalStyle}>
						<Typography variant="h6" marginBottom={2}>
							Cargar nueva facturación
						</Typography>
						<Grid container>
							<CreateBillingStepper accidentId={selectedAccident?.id!} handleClose={() => setShowBill(false)} />
						</Grid>
					</Box>
				</Modal>
			)}
		</Dialog>
	);
};
