import { CircularProgress, Grid, Typography, Box } from '@mui/material';
import { ProductCard } from '../components/products/ProductCard';
import { IProductPreview } from '../api/interfaces/interfaces';
import { ProductPreviewSkeleton } from '../components/products/ProductPreviewSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';

interface InitialViewProps {
	productsData: IProductPreview[];
	loading: boolean;
	messageLayout?: string;
	loadMoreProducts: () => void;
	hasMore: boolean;
}

export const InitialProductsView = (props: InitialViewProps) => {
	const { productsData, loading, messageLayout, hasMore, loadMoreProducts } = props;

	return (
		<InfiniteScroll
			dataLength={productsData.length}
			next={loadMoreProducts}
			hasMore={hasMore}
			loader={
				<Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
					<CircularProgress />
				</Box>
			}
			endMessage={
				<Typography align="center" variant="subtitle1" color="textSecondary">
					No hay más productos
				</Typography>
			}>
			<Grid
				width="100%"
				container
				sx={{
					height: 'auto',
					backgroundColor: '#111111',
					overflowY: 'hidden',
					mb: '1.5rem',
					pl: { xs: 0, sm: 0, md: '10rem' },
					pr: { xs: 0, sm: 0, md: '10rem' },
				}}>
				<Grid item width="100%">
					<Typography
						sx={{
							color: 'white',
							fontSize: '1rem',
							fontFamily: 'Roboto',
							fontWeight: 'bold',
							marginLeft: '1rem',
							marginTop: '1rem',
							marginBottom: '1rem',
						}}>
						{messageLayout}:
					</Typography>
				</Grid>

				{!loading
					? productsData.map((item) => (
							<Grid item key={item.id} xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginBottom: '1rem' }}>
								<ProductCard product={item} />
							</Grid>
						))
					: Array(12)
							.fill(0)
							.map((_, index) => (
								<Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3} padding={2}>
									<ProductPreviewSkeleton />
								</Grid>
							))}
			</Grid>
		</InfiniteScroll>
	);
};
