import { DocumentImage, NewDocumentImage } from './interfaces/interfaces';
import { axiosService } from './axiosService';

//// DOCUMENTOS

export const getAllAccidentDocumentsByOwner = async (accidentId: string): Promise<DocumentImage[]> => {
	try {
		const response = await axiosService.get(`/documentImages/accidentDocumentsImageByOwner/${accidentId}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return [];
		}
	} catch (error) {
		console.error('Error fetching insured data:', error);
		return [];
	}
};
export const getAllVehicleDocumentsByOwner = async (vehicleId: string): Promise<DocumentImage[]> => {
	try {
		const response = await axiosService.get(`/documentImages/vehicleDocumentsImageByOwner/${vehicleId}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return [];
		}
	} catch (error) {
		console.error('Error fetching insured data:', error);
		return [];
	}
};
export const getAllInsuredDocumentsByOwner = async (insuredId: string): Promise<DocumentImage[]> => {
	try {
		const response = await axiosService.get(`/documentImages/insuredDocumentsImageByOwner/${insuredId}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return [];
		}
	} catch (error) {
		console.error('Error fetching insured data:', error);
		return [];
	}
};

export const saveDocuments = async (documentsData: NewDocumentImage[]): Promise<any> => {
	try {
		const response = await axiosService.post(`/documentImages/saveManyDocuments`, documentsData);
		return response.status;
	} catch (error) {
		console.error('Error creating insured:', error);
		return null;
	}
};

export const getDocument = async (id: string): Promise<any> => {
	try {
		const response = await axiosService.get(`/documentImages/documentById/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by ID:', error);
		return null;
	}
};

export const deleteDocumentById = async (id: string): Promise<any> => {
	try {
		const response = await axiosService.delete(`/documentImages/deleteDocumentById/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error deleting insured by ID:', error);
		return null;
	}
};
