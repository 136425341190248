import { useState, useEffect } from 'react';
import { Box, Button, Grid, TableContainer, Typography, Modal } from '@mui/material';
import { Accident, DocumentImage, InsuranceCompany, Insured, InsuredVehicle } from '../../../../api/interfaces/interfaces';
import { DataTable } from './../billing/DataTable';
import { getAccidentById, getInsuranceCompanyDataByName, getInsuredById, getInsuredVehicle } from '../../../../api/insuredApi';
import { getAllVehicleDocumentsByOwner } from '../../../../api/documentsApi';
import { StatsBox } from './StatsBox';
import { SearchBox } from './SearchBox';
import { ManageInsuranceCompanies } from './ManageInsuranceCompanies';

const modalStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 780,
	bgcolor: 'black',
	color: 'white',
	boxShadow: 24,
	p: 2,
	borderRadius: 2,
};

interface BillingViewProps {
	billingList: Accident[];
	setBillingList?: React.Dispatch<React.SetStateAction<Accident[]>>;
	loading?: boolean;
	error?: string | null;
}
export const BillingView = (props: BillingViewProps) => {
	const { billingList, setBillingList, loading, error } = props;
	const [modalOpen, setModalOpen] = useState(false);
	const [accidentData, setAccidentData] = useState<Accident>();
	const [vehicleData, setVehicleData] = useState<InsuredVehicle>();
	const [insuranceCompanyData, setInsuranceCompanyData] = useState<InsuranceCompany>();
	const [toEmail, setToEmail] = useState<string>('');
	const [selectedCompany, setSelectedCompany] = useState<string>('');
	const [subject, setSubject] = useState<string>('');
	const [emailBody, setEmailBody] = useState<string>('');
	const [insuredData, setInsuredData] = useState<Insured | null>(null);
	const [documents, setDocuments] = useState<DocumentImage[]>();

	const handleOpen = () => setModalOpen(true);
	const handleClose = () => setModalOpen(false);

	const loadAccidentData = async (accidentId: string) => {
		const accident = await getAccidentById(accidentId);
		accident && setAccidentData(accident);
		return accident;
	};

	const loadVehicleData = async (vehicleId: string) => {
		const vehicle = await getInsuredVehicle(vehicleId);
		vehicle && setVehicleData(vehicle);
		return vehicle;
	};

	const loadCompanyData = async (companyName: string) => {
		const company = await getInsuranceCompanyDataByName(companyName);
		if (company) {
			setInsuranceCompanyData(company);
			setSelectedCompany(company.companyName);
			setToEmail(company.email);
			setEmailBody(company.emailModelText);
			if (accidentData) {
				setSubject(`Facturación siniestro Nº ${accidentData.accidentNumber}`);
			}
		}
	};

	const firstLoad = async (accidentId: string) => {
		accidentId && loadAccidentData(accidentId).then((data) => loadVehicleData(data?.insuredVehicleId!).then((data) => loadCompanyData(data?.insuranceProduct?.productCompany!)));
	};

	const loadDocuments = async () => {
		if (vehicleData?.id) {
			// const rta = await getAllAccidentDocumentsByOwner(accidentData?.id!).then(()=>{});
			// const rta = await getAllInsuredDocumentsByOwner(accidentData?.insured!);
			const rta = await getAllVehicleDocumentsByOwner(vehicleData.id);
			setDocuments(rta);
		}
	};

	// useEffect(() => {
	// 	if (!accidentData && !vehicleData && !insuranceCompanyData) firstLoad(billingList);
	// }, [billingList]);

	useEffect(() => {
		vehicleData?.id !== null && loadDocuments();
	}, [vehicleData]);

	useEffect(() => {
		if (accidentData) {
			setSubject(`Facturación siniestro Nº ${accidentData.accidentNumber}`);
		}
	}, [accidentData]);

	useEffect(() => {
		if (accidentData?.insured) {
			getInsuredById(accidentData.insured).then((data) => {
				if (data) setInsuredData(data);
			});
		}
	}, [accidentData]);

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleOpen}
				sx={{
					position: 'absolute',
					right: 300,
					top: 116,
					backgroundColor: '#a70d15',
					color: 'white',
					'&:hover': {
						backgroundColor: '#8a1218',
						borderColor: '#8a1218',
					},
				}}>
				Gestionar aseguradoras
			</Button>

			<Modal open={modalOpen} onClose={handleClose}>
				<Box sx={modalStyle}>
					<Typography variant="h6" marginBottom={2}>
						Cargar nueva facturación
					</Typography>

					<Grid container>
						<ManageInsuranceCompanies />
					</Grid>
				</Box>
			</Modal>

			<Grid container spacing={2} marginTop={2}>
				<StatsBox billingList={billingList} />
			</Grid>

			{/* Tabla con búsqueda y listado de billing mails */}
			<TableContainer sx={{ marginTop: 4, padding: 2 }}>
				<SearchBox setBillingList={setBillingList!} />
				<DataTable accidentList={billingList} setAccidentList={setBillingList!} />
			</TableContainer>
		</>
	);
};
