import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Stepper, Step, StepLabel, SelectChangeEvent } from '@mui/material';
import styled from '@emotion/styled';
import {
	CreateInsuredData,
	InsuredVehicle,
	Insured,
	IVehicleBrand,
	IVehicleModel,
	NewDocumentImage,
} from '../../../../api/interfaces/interfaces';
import { createInsured } from '../../../../api/insuredApi';
import { getVehicleBrands } from '../../../../api/brandsApi';
import { getVehicleModelsByBrand } from '../../../../api/modelsApi';
import { PersonalStep } from './CreateInsuredSteps/PersonalStep';
import { AddressStep } from './CreateInsuredSteps/AddressStep';
import { VehiclesStep } from './CreateInsuredSteps/VehiclesStep';
import { saveDocuments } from '../../../../api/documentsApi';
import { PersonalDocumentsStep } from './CreateInsuredSteps/PersonalDocumentsStep';

interface CreateInsuredStepperProps {
	handleClose: () => void;
	setInsuredList: React.Dispatch<React.SetStateAction<Insured[]>>;
}

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputLabel-root': {
		color: 'white',
		'&.Mui-focused': {
			color: 'white',
		},
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: 'black',
		color: 'white',
		'& fieldset': {
			borderColor: '#9e1616',
		},
		'&:hover fieldset': {
			borderColor: '#9e1616',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#9e1616',
		},
	},
}));

export const CreateInsuredStepper = (props: CreateInsuredStepperProps) => {
	const { handleClose, setInsuredList } = props;
	const steps = ['Información personal', 'Dirección y contacto', 'Documentacion de asegurado', 'Vehículos'];
	const [activeStep, setActiveStep] = useState(0);
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [images, setImages] = useState<NewDocumentImage[]>([]);

	const [formData, setFormData] = useState<CreateInsuredData>({
		firstname: '',
		lastname: '',
		email: '',
		personType: '',
		docType: '',
		cuit: '',
		country: 'Argentina',
		province: 'Buenos Aires',
		city: 'General Alvear',
		street: '',
		cellphone: '',
		typeIVA: '',
		birthday: '',
		maritalStatus: '',
		nacionality: 'Argentina',
		zipcode: '7263',
		insuredVehicles: [],
	});

	const [vehicles, setVehicles] = useState<InsuredVehicle[]>([
		{
			// id: '',
			vehicleBrand: '',
			vehicleModel: '',
			vehicleYear: '',
			plateNumber: '',
			insuranceProduct: {
				productName: '',
				productCompany: '',
				description: '',
			},
		},
	]);

	const [vehicleBrands, setVehicleBrands] = useState<IVehicleBrand[]>([]);
	const [vehicleModels, setVehicleModels] = useState<IVehicleModel[]>([]);

	const addImages = (reader: FileReader, file: File, blob: Blob, docReference: string, plateNumber: string) => {
		setImages((prevFiles) => [
			...prevFiles,
			{
				name: file.name,
				imageData: reader.result?.toString()!,
				mimeType: blob.type,
				imageSide: blob.size?.toString(),
				documentReference: docReference ?? 'otros',
				ownerInsuredID: null,
				ownerInsuredVehicleID: null,
				ownerAccidentID: null,
				plateNumber: plateNumber,
			},
		]);
	};

	useEffect(() => {
		if (activeStep === 2 && vehicleBrands?.length === 0) {
			getVehicleBrands()
				.then((brands) => {
					setVehicleBrands(brands);
				})
				.catch((error) => console.error('Error al obtener marcas', error));
		}
	}, [activeStep, vehicleBrands]);

	const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSelectChange = (e: SelectChangeEvent<string>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name!]: value }));
	};

	const handleVehicleChange = (
		index: number,
		field: keyof InsuredVehicle | keyof InsuredVehicle['insuranceProduct'],
		value: string,
	) => {
		const updatedVehicles = [...vehicles];
		if (field === 'productName' || field === 'productCompany' || field === 'description') {
			updatedVehicles[index] = {
				...updatedVehicles[index],
				insuranceProduct: {
					...updatedVehicles[index].insuranceProduct,
					[field]: value,
				},
			};
		} else {
			updatedVehicles[index] = { ...updatedVehicles[index], [field]: value } as InsuredVehicle;
		}
		setVehicles(updatedVehicles);
	};

	const handleBrandChange = (index: number, brand: string) => {
		handleVehicleChange(index, 'vehicleBrand', brand);
		// if (!vehicleModels[brand]) {
		getVehicleModelsByBrand(vehicleBrands.find((data) => data.name === brand)?.id!)
			.then((models) => {
				setVehicleModels(models);
			})
			.catch((error) => console.error('Error al obtener modelos', error));
		// }
	};

	const addVehicle = () => {
		setVehicles((prev) => [
			...prev,
			{
				vehicleBrand: '',
				vehicleModel: '',
				vehicleYear: '',
				plateNumber: '',
				insuranceProduct: { productName: '', productCompany: '', description: '' },
			},
		]);
	};

	const removeVehicle = (index: number) => {
		setVehicles((prev) => prev.filter((_, i) => i !== index));
	};

	const validateForm = (): boolean => {
		const newErrors: { [key: string]: string } = {};

		if (activeStep === 0) {
			if (!formData.firstname || formData.firstname.trim().length < 2) {
				newErrors.firstname = 'El nombre es obligatorio y debe tener al menos 2 caracteres';
			}
			if (!formData.lastname || formData.lastname.trim().length < 2) {
				newErrors.lastname = 'El apellido es obligatorio y debe tener al menos 2 caracteres';
			}
			if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
				newErrors.email = 'El correo electrónico no es válido';
			}
			if (!formData.personType) {
				newErrors.personType = 'El tipo de persona es obligatorio';
			}
			if (!formData.docType) {
				newErrors.docType = 'El tipo de documento es obligatorio';
			}
			if (!formData.cuit || !/^\d{7,11}$/.test(formData.cuit)) {
				newErrors.cuit = 'El CUIT/DNI debe tener entre 7 y 11 dígitos';
			}
			if (!formData.maritalStatus) {
				newErrors.maritalStatus = 'El estado civil es obligatorio';
			}
			if (!formData.nacionality) {
				newErrors.nacionality = 'La nacionalidad es obligatoria';
			}
			if (!formData.birthday) {
				newErrors.birthday = 'La fecha de nacimiento es obligatoria';
			} else {
				const birthdayDate = new Date(formData.birthday);
				const age = new Date().getFullYear() - birthdayDate.getFullYear();
				if (isNaN(birthdayDate.getTime()) || age < 18) {
					newErrors.birthday = 'Debes ingresar una fecha válida y ser mayor de 18 años';
				}
			}
		}

		if (activeStep === 1) {
			if (!formData.country) {
				newErrors.country = 'El país es obligatorio';
			}
			if (!formData.province) {
				newErrors.province = 'La provincia es obligatoria';
			}
			if (!formData.city) {
				newErrors.city = 'La ciudad es obligatoria';
			}
			if (!formData.street) {
				newErrors.street = 'La calle es obligatoria';
			}
			if (!formData.cellphone || !/^\d{10,15}$/.test(formData.cellphone)) {
				newErrors.cellphone = 'El número de celular debe tener entre 10 y 15 dígitos';
			}
			if (!formData.typeIVA) {
				newErrors.typeIVA = 'El tipo de IVA es obligatorio';
			}
			if (!formData.zipcode || !/^\d{4,6}$/.test(formData.zipcode)) {
				newErrors.zipcode = 'El código postal debe tener entre 4 y 6 dígitos';
			}
		}

		if (activeStep === 3) {
			if (vehicles.length === 0) {
				newErrors.vehicles = 'Debe agregar al menos un vehículo';
			} else {
				vehicles.forEach((veh, index) => {
					if (!veh.vehicleBrand) {
						newErrors[`vehicleBrand_${index}`] = 'La marca es obligatoria';
					}
					if (!veh.vehicleModel) {
						newErrors[`vehicleModel_${index}`] = 'El modelo es obligatorio';
					}
					if (!veh.vehicleYear) {
						newErrors[`vehicleYear_${index}`] = 'El año es obligatorio';
					} else {
						const year = parseInt(veh.vehicleYear);
						const currentYear = new Date().getFullYear();
						if (isNaN(year) || year < 1970 || year > currentYear) {
							newErrors[`vehicleYear_${index}`] = `El año debe estar entre 1970 y ${currentYear}`;
						}
					}
					if (!veh.plateNumber) {
						newErrors[`plateNumber_${index}`] = 'La patente es obligatoria';
					}
					if (veh.plateNumber && veh.plateNumber.length > 7) {
						newErrors[`plateNumber_${index}`] = 'La patente debe tener hasta 7 caracteres';
					}
					if (!veh.insuranceProduct.productCompany) {
						newErrors[`productCompany_${index}`] = 'La compañía es obligatoria';
					}
					if (!veh.insuranceProduct.productName) {
						newErrors[`productName_${index}`] = 'El producto es obligatorio';
					}
					if (veh.insuranceProduct.productCompany === 'Otra' && !veh.insuranceProduct.description) {
						newErrors[`productCompanyOther_${index}`] = 'Debe completar la otra compañía';
					}
				});
			}
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleNext = () => {
		if (validateForm()) {
			if (activeStep === steps.length - 1) {
				handleSubmit();
			} else {
				setActiveStep((prev) => prev + 1);
			}
		}
	};

	const handleBack = () => {
		setActiveStep((prev) => prev - 1);
	};

	const handleSubmit = async () => {
		const finalData: CreateInsuredData = { ...formData, insuredVehicles: vehicles };
		try {
			//Mostrar spinner con mensaje: Guardado asegurado
			const result = await createInsured(finalData).then(async (data) => {
				//
				if (data?.status === 201) {
					if (images.length > 0) {
						//cambiar el mensaje del spinner a: Guardando archivos
						images.forEach((img, i) => {
							data?.vehicles.forEach((v: any) => {
								if (img.plateNumber === v.plateNumber) images[i].ownerInsuredVehicleID = v.insuredVehicleID;
							});
							if (img.plateNumber === 'insured') images[i].ownerInsuredID = data?.insuredID!;
						});
						const rta = await saveDocuments(images);
						if (rta === 201) {
							alert('Asegurado creado exitosamente!');
							handleClose();
						} else alert('Hubo un error al guardar los documentos.');
					}
				} else if (data?.status === 409) alert('El asegurado ya existe.');
				else alert('Hubo un error al crear asegurado.');
			});
		} catch (error) {
			console.error('Error al crear el asegurado:', error);
			alert('Ocurrió un error inesperado. Por favor, inténtalo nuevamente.');
		}
	};

	return (
		<>
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				sx={{
					backgroundColor: 'black',
					'& .MuiStepLabel-root': { color: 'white' },
					'& .MuiStepLabel-label': { color: 'white' },
					'& .MuiStepLabel-label.Mui-active': { color: 'white' },
					'& .MuiStepLabel-label.Mui-completed': { color: 'white' },
					'& .Mui-disabled .MuiStepLabel-label': { color: 'rgba(255, 255, 255, 0.5)' },
				}}>
				{steps.map((label, index) => (
					<Step key={index}>
						<StepLabel sx={{ color: 'white' }}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<Box sx={{ marginTop: 2, backgroundColor: 'black', width: '100%' }}>
				{activeStep === 0 && (
					<PersonalStep
						formData={formData}
						errors={errors}
						handleTextFieldChange={handleTextFieldChange}
						handleSelectChange={handleSelectChange}
					/>
				)}
				{activeStep === 1 && (
					<AddressStep formData={formData} errors={errors} handleTextFieldChange={handleTextFieldChange} />
				)}
				{activeStep === 2 && <PersonalDocumentsStep files={images} addImages={addImages} />}
				{activeStep === 3 && (
					<VehiclesStep
						vehicles={vehicles}
						errors={errors}
						files={images}
						addImages={addImages}
						vehicleBrands={vehicleBrands}
						vehicleModels={vehicleModels}
						handleBrandChange={handleBrandChange}
						handleVehicleChange={handleVehicleChange}
						addVehicle={addVehicle}
						removeVehicle={removeVehicle}
					/>
				)}
				<Box sx={{ marginTop: 2 }}>
					<Button
						sx={{
							backgroundColor: '#a70d15',
							color: 'white',
							'&:hover': { backgroundColor: '#8a1218' },
						}}
						onClick={handleBack}
						disabled={activeStep === 0}
						variant="contained">
						Atrás
					</Button>
					{activeStep === 3 && (
						<Button
							onClick={addVehicle}
							variant="contained"
							sx={{
								marginLeft: 2,
								backgroundColor: '#a70d15',
								color: 'white',
								'&:hover': { backgroundColor: '#8a1218' },
							}}>
							Agregar Vehículo
						</Button>
					)}
					<Button
						sx={{
							marginLeft: 2,
							backgroundColor: '#a70d15',
							color: 'white',
							'&:hover': { backgroundColor: '#8a1218' },
						}}
						onClick={handleNext}
						variant="contained">
						{activeStep === steps.length - 1 ? 'Crear Asegurado' : 'Siguiente'}
					</Button>
				</Box>
			</Box>
		</>
	);
};
