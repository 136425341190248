import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DocumentImage, Insured, InsuredVehicle } from '../../../../api/interfaces/interfaces';
import { getAllInsuredVehicles } from '../../../../api/insuredApi';
import { getAllInsuredDocumentsByOwner, getAllVehicleDocumentsByOwner } from '../../../../api/documentsApi';

interface InsuredDetailsProps {
    openModal: boolean;
    handleCloseModal: () => void;
    insuredList: Insured | null;
}

export const InsuredDetails = (props: InsuredDetailsProps) => {
    const { openModal, handleCloseModal, insuredList } = props;

    const [insuredVehicles, setInsuredVehicles] = useState<InsuredVehicle[] | null>(null);
    const [insuredDocuments, setInsuredDocuments] = useState<DocumentImage[]>([]);
    const [vehicleDocuments, setVehicleDocuments] = useState<Record<string, DocumentImage[]>>({});
    const [openVehicleModal, setOpenVehicleModal] = useState<boolean>(false);
    const [selectedVehicle, setSelectedVehicle] = useState<InsuredVehicle | null>(null);

    useEffect(() => {
        if (insuredList?.id) {
            getAllInsuredVehicles(insuredList.id)
                .then((data) => setInsuredVehicles(data))
                .catch((error) => {
                    console.error('Error fetching insured vehicles:', error);
                    setInsuredVehicles(null);
                });

            getAllInsuredDocumentsByOwner(insuredList.id)
                .then((data) => setInsuredDocuments(data))
                .catch((error) => {
                    console.error('Error fetching insured documents:', error);
                    setInsuredDocuments([]);
                });
        }
    }, [insuredList]);

    useEffect(() => {
        if (insuredVehicles && insuredVehicles.length > 0) {
            Promise.all(
                insuredVehicles.map(vehicle =>
                    getAllVehicleDocumentsByOwner(vehicle.id as string).then(docs => ({
                        vehicleId: vehicle.id as string,
                        docs,
                    }))
                )
            )
                .then(results => {
                    const documentsMap: Record<string, DocumentImage[]> = {};
                    results.forEach(({ vehicleId, docs }) => {
                        documentsMap[vehicleId] = docs;
                    });
                    setVehicleDocuments(documentsMap);
                })
                .catch(error => {
                    console.error('Error fetching vehicle documents:', error);
                    setVehicleDocuments({});
                });
        }
    }, [insuredVehicles]);

    const handleOpenVehicleModal = (vehicle: InsuredVehicle) => {
        setSelectedVehicle(vehicle);
        setOpenVehicleModal(true);
    };

    const handleCloseVehicleModal = () => {
        setOpenVehicleModal(false);
        setSelectedVehicle(null);
    };

    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth="md"
                fullWidth
                sx={{ height: 650, position: 'absolute', top: '11%', backgroundColor: 'transparent' }}
                PaperProps={{
                    sx: { backgroundColor: 'black', borderRadius: 3, border: '1px inset white' }
                }}
            >
                <Box sx={{ color: 'white', p: 2 }}>


                    <Typography variant="h6" sx={{ textAlign: 'center', color: 'white', fontWeight: 300 }}>
                        Vista de detalle de asegurado
                    </Typography>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ p: 2, borderRadius: 3 }}>
                                    <Typography variant="h6" sx={{ textAlign: 'center', color: 'white', fontWeight: 300 }}>
                                        Detalles
                                    </Typography>
                                    {insuredList && (
                                        <>
                                            <div style={{ marginTop: 20, fontSize: 13 }}>
                                                <strong>Nombre:</strong> {insuredList.firstname} {insuredList.lastname}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>

                                                <strong>Tipo de persona:</strong> {insuredList.personType}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>

                                                <strong>Documento:</strong> {insuredList.docType} {insuredList.cuit}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>

                                                <strong>Dirección:</strong> {insuredList.street}, {insuredList.city}, {insuredList.province}, {insuredList.country}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>

                                                <strong>Piso:</strong> {insuredList.floor || 'N/A'}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Teléfono celular:</strong> {insuredList.cellphone}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Correo electrónico:</strong> {insuredList.email}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Fecha de nacimiento:</strong> {insuredList.birthday}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Estado civil:</strong> {insuredList.maritalStatus}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Estado:</strong> {insuredList.status}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Código postal:</strong> {insuredList.zipcode}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Nacionalidad:</strong> {insuredList.nacionality}
                                            </div>
                                            <div style={{ marginTop: 10, fontSize: 13 }}>


                                                <strong>Fecha de creación:</strong> {insuredList.createdAt}
                                            </div>
                                        </>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box sx={{ p: 2, borderRadius: 3, height: '40vh', overflow: 'auto' }}>
                                    <Typography variant="h6" sx={{ textAlign: 'center', color: 'white', fontWeight: 300 }}>
                                        Documentos e imágenes
                                    </Typography>
                                    {insuredDocuments.length > 0 ? (
                                        <Grid container spacing={2} justifyContent="center">
                                            {insuredDocuments.map((doc) => {
                                                const isImage = doc.mimeType.startsWith("image/");
                                                const isPDF = doc.mimeType === "application/pdf";

                                                let fileSrc = doc.imageData;
                                                if (!fileSrc.startsWith("data:")) {
                                                    fileSrc = `data:${doc.mimeType};base64,${fileSrc}`;
                                                }

                                                return (
                                                    <Grid item key={doc.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Box sx={{
                                                            width: '120px',
                                                            height: '140px',
                                                            border: '1px inset white',
                                                            p: 1,
                                                            borderRadius: 2,
                                                            mt: 2,
                                                            textAlign: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }}>
                                                            <Typography variant="subtitle2" sx={{ fontSize: '0.8rem' }}>
                                                                {doc.documentReference}
                                                            </Typography>
                                                            {isImage && (
                                                                <img
                                                                    src={fileSrc}
                                                                    alt={doc.name}
                                                                    style={{ width: '100%', height: 'auto', borderRadius: 4, maxHeight: '80px' }}
                                                                />
                                                            )}
                                                            {isPDF && (
                                                                <Box sx={{ mt: 1, textAlign: 'center' }}>
                                                                    <a
                                                                        href={fileSrc}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download={doc.name}
                                                                        style={{ color: 'white', textDecoration: 'none' }}
                                                                    >
                                                                        📄 Descargar
                                                                    </a>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    ) : (
                                        <Typography variant="body1" sx={{ textAlign: 'center', mt: 2, color: 'gray' }}>
                                            No hay imágenes o documentos cargados para este asegurado.
                                        </Typography>
                                    )}
                                </Box>



                                <Typography variant="h6" sx={{ textAlign: 'center', color: 'white', fontWeight: 300 }}>
                                    Vehículos
                                </Typography>

                                <Box
                                    sx={{
                                        overflowY: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        mt: 2,
                                        pr: 1,
                                        maxHeight: '15vh'
                                    }}
                                >
                                    {insuredVehicles && insuredVehicles.length > 0 ? (
                                        insuredVehicles.map((vehicle, index) => (
                                            <>
                                                <Button
                                                    key={vehicle.id || index}
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleOpenVehicleModal(vehicle)}
                                                    sx={{
                                                        width: '100%',
                                                        border: '1px solid white',
                                                        color: 'white',
                                                        backgroundColor: '#13273d',
                                                        '&:hover': {
                                                            backgroundColor: '#22548c',
                                                        },
                                                    }}
                                                >
                                                    {`${vehicle.vehicleBrand.toUpperCase()} ${vehicle.vehicleModel.toUpperCase()} ${vehicle.vehicleYear}`}
                                                </Button>
                                            </>
                                        ))
                                    ) : (
                                        <Typography variant="body1" sx={{ textAlign: 'center', mt: 2, color: 'gray' }}>
                                            No hay vehículos registrados para este asegurado.
                                        </Typography>
                                    )}
                                </Box>

                            </Grid>
                        </Grid>
                    </DialogContent>


                </Box>
            </Dialog>

            {selectedVehicle && (
                <Dialog open={openVehicleModal} onClose={handleCloseVehicleModal} sx={{ marginTop: 9 }} PaperProps={{
                    sx: { backgroundColor: 'black', borderRadius: 3, border: '1px solid #22548c' }
                }}>
                    <Box sx={{ backgroundColor: 'black', color: 'white', minHeight: '70vh', maxHeight: '70vh' }}>
                        <DialogTitle sx={{ color: 'white', fontWeight: 300 }}>Detalles del vehículo asegurado</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        gap: 2
                                    }}>
                                        <Typography variant="body2" sx={{ fontSize: 13 }}><strong>Marca:</strong> {selectedVehicle.vehicleBrand}</Typography>
                                        <Typography variant="body2" sx={{ fontSize: 13 }}><strong>Modelo:</strong> {selectedVehicle.vehicleModel}</Typography>
                                        <Typography variant="body2" sx={{ fontSize: 13 }}><strong>Año:</strong> {selectedVehicle.vehicleYear}</Typography>
                                        <Typography variant="body2" sx={{ fontSize: 13 }}><strong>Patente:</strong> {selectedVehicle.plateNumber}</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    {selectedVehicle.id && vehicleDocuments[selectedVehicle.id]?.length > 0 ? (
                                        <Box sx={{
                                            width: '100%',
                                            height: '400px',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            overflowY: 'auto'
                                        }}>
                                            {vehicleDocuments[selectedVehicle.id].map((doc) => {
                                                const isImage = doc.mimeType.startsWith("image/");
                                                const isPDF = doc.mimeType === "application/pdf";

                                                let fileSrc = doc.imageData;
                                                if (!fileSrc.startsWith("data:")) {
                                                    fileSrc = `data:${doc.mimeType};base64,${fileSrc}`;
                                                }

                                                return (
                                                    <Box key={doc.id} sx={{
                                                        width: 120,
                                                        height: 120,
                                                        p: 1,
                                                        border: '1px solid #22548c',
                                                        borderRadius: 2,
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                    }}>
                                                        <Typography variant="caption" sx={{ textTransform: 'lowercase', fontSize: '0.8rem' }}>
                                                            {doc.documentReference}
                                                        </Typography>
                                                        {isImage && (
                                                            <img
                                                                src={fileSrc}
                                                                alt={doc.name}
                                                                style={{ width: '100%', height: '80px', borderRadius: 4 }}
                                                            />
                                                        )}
                                                        {isPDF && (
                                                            <a href={fileSrc} target="_blank" download={doc.name}
                                                                style={{ color: 'white', textDecoration: 'none', fontSize: '0.8rem' }}>
                                                                📄 Descargar PDF
                                                            </a>
                                                        )}
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    ) : (
                                        <Typography variant="body2" sx={{ textAlign: 'center', color: 'gray' }}>
                                            No hay imágenes o documentos cargados para este vehículo.
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Box>
                </Dialog>
            )}

        </>
    );
};
