import { useState, useRef } from 'react';
import { NewDocumentImage } from '../../../../../api/interfaces/interfaces';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface PersonalDocumentsStepProps {
	files: NewDocumentImage[];
	addImages: (reader: FileReader, file: File, blob: Blob, docReference: string, plateNumber: string) => void;
}

export const PersonalDocumentsStep = (props: PersonalDocumentsStepProps) => {
	const { files, addImages } = props;
	const [filePreview, setFilePreview] = useState<string | null>(null);
	const imageInputRef = useRef<HTMLInputElement>(null);

	const convertFileToBlob = (file: File): Promise<Blob> => {
		return file.arrayBuffer().then((arrayBuffer) =>
			new Blob([new Uint8Array(arrayBuffer)], { type: file.type }),
		);
	};

	const handleFileChange = async (files: File[], docReference: string, plateNumber: string) => {
		try {
			await Promise.all(
				files.map(async (file) => {
					const blob = await convertFileToBlob(file);
					const reader = new FileReader();
					const imageDataPromise = new Promise<string | ArrayBuffer>((resolve, reject) => {
						reader.onloadend = () => resolve(reader.result as string | ArrayBuffer);
						reader.onerror = reject;
					});

					if (file.type.startsWith('image/')) {
						const readerPreview = new FileReader();
						readerPreview.onloadend = () => {
							setFilePreview(readerPreview.result as string);
						};
						readerPreview.readAsDataURL(file);
					}

					reader.readAsDataURL(blob);
					await imageDataPromise;

					addImages(reader, file, blob, docReference, plateNumber);
				}),
			);
		} catch (error) {
			console.error('Error al procesar las imágenes:', error);
		}
	};

	const clearPreview = () => {
		setFilePreview(null);
		if (imageInputRef.current) {
			imageInputRef.current.value = '';
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Box>
					<Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
						Documentos personales
					</Typography>
					<Grid container spacing={2} sx={{ marginLeft: 0 }}>
						<Grid item xs={12}>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Typography variant="caption" sx={{ color: 'white' }}>
									Licencia de conducir
								</Typography>
								<Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '30px' }}>
									<input
										ref={imageInputRef}
										type="file"
										accept="image/*"
										style={{ backgroundColor: '#333', padding: '4px', flex: 1 }}
										onChange={(e) =>
											handleFileChange(Array.from(e.target.files!), 'Licencia conducir', 'insured')
										}
									/>

									{filePreview && (
										<Box sx={{ display: 'flex', alignItems: 'center', ml: 2, position: 'relative' }}>
											<img
												src={filePreview}
												alt="Vista previa"
												style={{
													width: '40px',
													height: '40px',
													objectFit: 'cover',
													borderRadius: '8px',
													border: '1px solid #ddd',
												}}
											/>
											<IconButton
												size="small"
												onClick={clearPreview}
												sx={{
													position: 'absolute',
													top: 5,
													right: -30,
													color: '#b8a6a6',
													padding: '2px',
												}}
											>
												<DeleteIcon fontSize="small" />
											</IconButton>
										</Box>
									)}
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Typography variant="caption" sx={{ color: 'white' }}>
									Otros
								</Typography>
								<input
									type="file"
									accept=".pdf,.doc,.docx"
									style={{ backgroundColor: '#333', padding: '4px', width: '100%' }}
									onChange={(e) =>
										handleFileChange(Array.from(e.target.files!), 'otros', 'insured')
									}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};
