import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accident } from '../../../../api/interfaces/interfaces';
import { deleteAccidentById, getAllAccidentsByOwner } from '../../../../api/insuredApi';
import { useEffect, useState } from 'react';
import { AccidentDetails } from './AccidentDetails';

const StyledTableCell = styled(TableCell)({
    color: 'white',
});

const styles = {
    tableRow: {
        cursor: 'pointer',
        transition: 'background-color .1s ease-in-out',
        '&:hover': {
            backgroundColor: '#13273d',
        },
    },
};

interface DataTableProps {
    accidentList: Accident[];
    setAccidentList: React.Dispatch<React.SetStateAction<Accident[]>>;
}

export const DataTable = (props: DataTableProps) => {
    const { accidentList, setAccidentList } = props;
    const [filteredAccidents, setFilteredAccidents] = useState<Accident[]>([]);
    const [selectedAccident, setSelectedAccident] = useState<Accident | null>(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setFilteredAccidents(accidentList);
    }, [accidentList]);

    const handleDelete = async (id: string) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este siniestro?')) {
            try {
                await deleteAccidentById(id);
                const updatedList = await getAllAccidentsByOwner();
                setAccidentList(updatedList);
                alert('Siniestro eliminado correctamente');
            } catch (err) {
                console.error('Error al eliminar el siniestro:', err);
                alert('Hubo un error al intentar eliminar el siniestro');
            }
        }
    };

    const handleRowClick = (accident: Accident) => {
        setSelectedAccident(accident);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const formatInsuredName = (data: Accident) => {
        const { firstname, lastname } = data?.insuredOwner;
        return `${firstname} ${lastname}`;
    }
    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: '230px', overflow: 'auto', backgroundColor: 'transparent' }}>
                <Table sx={{ marginTop: 3, marginBottom: 2 }}>
                    <TableHead
                        sx={{
                            backgroundColor: '#8c1c20',
                            borderBottom: '3px solid black',
                            '& th': {
                                fontSize: '15px',
                                position: 'sticky',
                                top: 0,
                                backgroundColor: '#8c1c20',
                                zIndex: 1,
                            },
                        }}
                    >
                        <TableRow>
                            <StyledTableCell>Número de siniestro</StyledTableCell>
                            <StyledTableCell>Asegurado</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell>Estado</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAccidents.map((accident, index) => (
                            <TableRow
                                key={index}
                                sx={styles.tableRow}
                                onClick={() => handleRowClick(accident)}
                            >
                                <TableCell style={{ color: 'white', width: 200 }}>{accident.accidentNumber}</TableCell>
                                <TableCell style={{ color: 'white' }}>{formatInsuredName(accident)}</TableCell>
                                <TableCell style={{ color: 'white' }}>{accident.description}</TableCell>
                                <TableCell style={{ color: 'white' }}>{accident.status}</TableCell>
                                <TableCell style={{ color: 'white' }}>{new Date(accident.createdAt).toLocaleDateString()}</TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        sx={{
                                            cursor: 'pointer', color: '#b8a6a6'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(accident.id);
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AccidentDetails openModal={openModal} handleCloseModal={handleCloseModal} selectedAccident={selectedAccident} />
        </>
    );
};
