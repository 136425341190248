import { InputAdornment, InputBase, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Accident, BillingMail } from '../../../../api/interfaces/interfaces';

interface SearchBoxProps {
	setBillingList: React.Dispatch<React.SetStateAction<Accident[]>>;
}

export const SearchBox = (props: SearchBoxProps) => {
	const { setBillingList } = props;
	const [search, setSearch] = useState('');

	const debounceSearch = useCallback(() => {
		const handler = setTimeout(async () => {
			if (search.trim()) {
				// const data = await getBillingBySearch(search, 10, 1); algo si por ej
				// if (data) setBillingList(data);
			} else {
				// const allData = await getAllBillingMails(); y necesitamos algo asi
				// setBillingList(allData);
			}
		}, 300);
		return () => clearTimeout(handler);
	}, [search, setBillingList]);

	useEffect(() => {
		debounceSearch();
	}, [search, debounceSearch]);

	return (
		<Paper
			sx={{
				display: 'flex',
				width: '40%',
				alignItems: 'center',
				mb: 2,
				p: '4px 16px',
				borderRadius: 20,
				border: '1px solid #cf1717',
				backgroundColor: 'black',
			}}>
			<InputBase
				sx={{ backgroundColor: 'black', color: 'white' }}
				placeholder="nro siniestro, nombre, apellido, vehiculo"
				fullWidth
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				startAdornment={
					<InputAdornment position="start">
						<SearchIcon sx={{ color: 'white' }} />
					</InputAdornment>
				}
			/>
		</Paper>
	);
};
