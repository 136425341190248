import styled from '@emotion/styled';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Insured } from '../../../../api/interfaces/interfaces';
import { deleteInsuredById, getAllInsuredByOwner } from '../../../../api/insuredApi';
import { InsuredDetails } from './InsuredDetails';
import { useState } from 'react';


const StyledTableCell = styled(TableCell)({
    color: 'white',
});

const styles = {
    tableRow: {
        cursor: 'pointer',
        transition: 'background-color .1s ease-in-out',
        '&:hover': {
            backgroundColor: '#13273d',
        },
    },
};




interface DataTableProps {
    insuredList: Insured[];
    setInsuredList: React.Dispatch<React.SetStateAction<Insured[]>>;

}


export const DataTable = (props: DataTableProps) => {
    const { insuredList, setInsuredList } = props;
    const [selectedInsured, setSelectedInsured] = useState<Insured | null>(null);
    const [openModal, setOpenModal] = useState(false);

    const handleDelete = async (id: string) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este asegurado?')) {
            try {
                await deleteInsuredById(id);
                setInsuredList((prevList) => prevList.filter((insured) => insured.id !== id));
                const updatedList = await getAllInsuredByOwner();
                setInsuredList(updatedList);
                alert('Asegurado eliminado correctamente');
            } catch (err) {
                console.error('Error al eliminar el asegurado:', err);
                alert('Hubo un error al intentar eliminar el asegurado');
            }
        }
    };

    const handleRowClick = (accident: Insured) => {
        setSelectedInsured(accident);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: '230px', overflow: 'auto', backgroundColor: 'transparent' }}>
                <Table sx={{ marginTop: 3, marginBottom: 2 }}>
                    <TableHead
                          sx={{
                            backgroundColor: '#8c1c20',
                            borderBottom: '3px solid black',
                            '& th': {
                                fontSize: '15px',
                                position: 'sticky',
                                top: 0,
                                backgroundColor: '#8c1c20',
                                zIndex: 1,
                            },
                        }}
                    >
                        <TableRow>
                            <StyledTableCell>Nombre</StyledTableCell>
                            <StyledTableCell>Apellido</StyledTableCell>
                            <StyledTableCell>CUIT</StyledTableCell>
                            <StyledTableCell>Ciudad</StyledTableCell>
                            <StyledTableCell>Celular</StyledTableCell>
                            <StyledTableCell>Correo</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>


                    <TableBody>
                        {insuredList.map((insured, index) => (
                            <TableRow
                                key={index}
                                sx={styles.tableRow}
                                onClick={() => handleRowClick(insured)}
                            >
                                <TableCell style={{ color: 'white' }}>{insured.firstname}</TableCell>
                                <TableCell style={{ color: 'white' }}>{insured.lastname}</TableCell>
                                <TableCell style={{ color: 'white' }}>{insured.cuit}</TableCell>
                                <TableCell style={{ color: 'white' }}>{insured.city}</TableCell>
                                <TableCell style={{ color: 'white' }}>{insured.cellphone}</TableCell>
                                <TableCell style={{ color: 'white' }}>{insured.email}</TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        sx={{ cursor: 'pointer', color: '#b8a6a6' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(insured.id);
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <InsuredDetails openModal={openModal} handleCloseModal={handleCloseModal} insuredList={selectedInsured} />
        </>
    );
};