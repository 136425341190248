import { InsuranceCompany, InsuredVehicle, IVehicleBrand, IVehicleModel, NewDocumentImage } from '../../../../../api/interfaces/interfaces';
import { Box, Button, Divider, Grid, MenuItem, Select, TextField, Typography, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { useState, useRef, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllInsuranceCompanies } from '../../../../../api/insuredApi';

interface VehiclesStepProps {
	vehicles: InsuredVehicle[];
	errors: { [key: string]: string };
	vehicleBrands: IVehicleBrand[];
	vehicleModels: IVehicleModel[];
	files: NewDocumentImage[];
	addImages: (reader: FileReader, file: File, blob: Blob, docReference: string, plateNumber: string) => void;
	handleBrandChange: (index: number, brand: string) => void;
	handleVehicleChange: (index: number, field: keyof InsuredVehicle | keyof InsuredVehicle['insuranceProduct'], value: string) => void;
	addVehicle: () => void;
	removeVehicle: (index: number) => void;
}

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputLabel-root': {
		color: 'white',
		'&.Mui-focused': {
			color: 'white',
		},
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: 'black',
		color: 'white',
		'& fieldset': {
			borderColor: '#9e1616',
		},
		'&:hover fieldset': {
			borderColor: '#9e1616',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#9e1616',
		},
	},
}));

export const VehiclesStep = (props: VehiclesStepProps) => {
	const { vehicles, errors, vehicleBrands, vehicleModels, handleBrandChange, handleVehicleChange, files, addImages, removeVehicle } = props;

	const [filePreviews, setFilePreviews] = useState<{ [key: string]: string }>({});
	const [companies, setCompanies] = useState<InsuranceCompany[]>([]);
	const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});

	const convertFileToBlob = (file: File): Promise<Blob> => {
		return file.arrayBuffer().then((arrayBuffer) => new Blob([new Uint8Array(arrayBuffer)], { type: file.type }));
	};
	const loadCompanies = async () => {
		const company = await getAllInsuranceCompanies();
		setCompanies(company || []);
	};
	const handleFileChange = async (filesArr: File[], docReference: string, plateNumber: string, vehIndex: number) => {
		const key = `${vehIndex}-${docReference}`;
		try {
			await Promise.all(
				filesArr.map(async (file) => {
					const blob = await convertFileToBlob(file);
					const reader = new FileReader();
					const imageDataPromise = new Promise<string | ArrayBuffer>((resolve, reject) => {
						reader.onloadend = () => resolve(reader.result as string | ArrayBuffer);
						reader.onerror = reject;
					});

					if (file.type.startsWith('image/')) {
						const readerPreview = new FileReader();
						readerPreview.onloadend = () => {
							setFilePreviews((prev) => ({ ...prev, [key]: readerPreview.result as string }));
						};
						readerPreview.readAsDataURL(file);
					}

					reader.readAsDataURL(blob);
					await imageDataPromise;
					addImages(reader, file, blob, docReference, plateNumber);
				}),
			);
		} catch (error) {
			console.error('Error al procesar las imágenes:', error);
		}
	};

	const clearPreview = (key: string) => {
		setFilePreviews((prev) => ({ ...prev, [key]: '' }));
		if (inputRefs.current[key]) {
			inputRefs.current[key]!.value = '';
		}
	};
	useEffect(() => {
		loadCompanies();
	}, []);
	return (
		<Box>
			{errors.vehicles && (
				<Typography variant="body2" color="error" sx={{ mb: 1 }}>
					{errors.vehicles}
				</Typography>
			)}
			<Box sx={{ maxHeight: '400px', overflowY: 'auto', pr: 1 }}>
				{vehicles.map((veh, index) => (
					<Box
						key={index}
						sx={{
							border: '1px solid #9e1616',
							borderRadius: 2,
							padding: 2,
							marginBottom: 2,
							backgroundColor: '#1a1a1a',
							boxShadow: 3,
							position: 'relative',
						}}>
						{vehicles.length > 1 && (
							<Button
								onClick={() => removeVehicle(index)}
								sx={{
									position: 'absolute',
									top: 6,
									right: 12,
									minWidth: 'auto',
									padding: 0,
									color: 'white',
								}}>
								X
							</Button>
						)}
						<Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
							Datos del Vehículo {index + 1}
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} md={4}>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
									<Select
										fullWidth
										size="small"
										name="vehicleBrand"
										value={veh.vehicleBrand}
										onChange={(e) => handleBrandChange(index, e.target.value)}
										displayEmpty
										sx={{
											width: '100%',
											height: '32px',
											'& .MuiOutlinedInput-root': {
												height: '32px',
												'& .MuiSelect-select': {
													padding: '4px 8px',
													fontSize: '0.8rem',
												},
											},
											color: 'white',
											backgroundColor: 'black',
											border: '1px solid #9e1616',
											'.MuiSelect-icon': { color: 'white' },
										}}>
										<MenuItem value="" disabled>
											Marca
										</MenuItem>
										{vehicleBrands.map((brand, i) => (
											<MenuItem key={i} value={brand.name}>
												{brand.name}
											</MenuItem>
										))}
									</Select>
									{errors[`vehicleBrand_${index}`] && (
										<Typography variant="caption" color="error">
											{errors[`vehicleBrand_${index}`]}
										</Typography>
									)}
									<Select
										fullWidth
										size="small"
										name="vehicleModel"
										value={veh.vehicleModel}
										onChange={(e) => handleVehicleChange(index, 'vehicleModel', e.target.value)}
										displayEmpty
										sx={{
											width: '100%',
											height: '32px',
											'& .MuiOutlinedInput-root': {
												height: '32px',
												'& .MuiSelect-select': {
													padding: '4px 8px',
													fontSize: '0.8rem',
												},
											},
											color: 'white',
											backgroundColor: 'black',
											border: '1px solid #9e1616',
											'.MuiSelect-icon': { color: 'white' },
										}}>
										<MenuItem value="" disabled>
											Modelo
										</MenuItem>
										{veh.vehicleBrand &&
											vehicleModels?.map((model, i) => (
												<MenuItem key={i} value={model.name}>
													{model.name}
												</MenuItem>
											))}
									</Select>
									{errors[`vehicleModel_${index}`] && (
										<Typography variant="caption" color="error">
											{errors[`vehicleModel_${index}`]}
										</Typography>
									)}
									<StyledTextField
										fullWidth
										size="small"
										label="Año"
										name="vehicleYear"
										type="number"
										value={veh.vehicleYear}
										onChange={(e) => handleVehicleChange(index, 'vehicleYear', e.target.value)}
										inputProps={{
											min: 1970,
											max: new Date().getFullYear(),
										}}
										sx={{
											'& .MuiOutlinedInput-root': {
												height: '32px',
												'& input': {
													padding: '4px 8px',
												},
											},
										}}
										error={!!errors[`vehicleYear_${index}`]}
										helperText={errors[`vehicleYear_${index}`]}
									/>
									<StyledTextField
										fullWidth
										size="small"
										label="Patente"
										name="plateNumber"
										value={veh.plateNumber}
										onChange={(e) => handleVehicleChange(index, 'plateNumber', e.target.value)}
										inputProps={{ maxLength: 7 }}
										sx={{
											'& .MuiOutlinedInput-root': {
												height: '32px',
												'& input': {
													padding: '4px 8px',
												},
											},
										}}
										error={!!errors[`plateNumber_${index}`]}
										helperText={errors[`plateNumber_${index}`]}
									/>
									<Divider sx={{ height: 1, backgroundColor: 'white' }} />

									<Select
										fullWidth
										size="small"
										name="productCompany"
										value={veh.insuranceProduct.productCompany}
										onChange={(e) => handleVehicleChange(index, 'productCompany', e.target.value)}
										displayEmpty
										sx={{
											width: '100%',
											height: '32px',
											'& .MuiOutlinedInput-root': {
												height: '32px',
												'& .MuiSelect-select': {
													padding: '4px 8px',
													fontSize: '0.8rem',
												},
											},
											color: 'white',
											backgroundColor: 'black',
											border: '1px solid #9e1616',
											'.MuiSelect-icon': { color: 'white' },
										}}>
										<MenuItem value="" disabled>
											Compañía de seguro
										</MenuItem>
										{companies.map((cmp) => (
											<MenuItem value={cmp.companyName}>{cmp.companyName}</MenuItem>
										))}
										<MenuItem value="Otra">Otra</MenuItem>
									</Select>
									{errors[`productCompany_${index}`] && (
										<Typography variant="caption" color="error">
											{errors[`productCompany_${index}`]}
										</Typography>
									)}
									{veh.insuranceProduct.productCompany === 'Otra' && (
										<StyledTextField
											fullWidth
											size="small"
											sx={{
												'& .MuiOutlinedInput-root': {
													height: '32px',
													'& input': {
														padding: '4px 8px',
													},
												},
											}}
											label="Otra compañía"
											value={veh.insuranceProduct.description || ''}
											onChange={(e) => handleVehicleChange(index, 'description', e.target.value)}
											error={!!errors[`productCompanyOther_${index}`]}
											helperText={errors[`productCompanyOther_${index}`]}
										/>
									)}
									<Select
										fullWidth
										size="small"
										name="productName"
										value={veh.insuranceProduct.productName}
										onChange={(e) => handleVehicleChange(index, 'productName', e.target.value)}
										displayEmpty
										sx={{
											color: 'white',
											backgroundColor: 'black',
											border: '1px solid #9e1616',
											'.MuiSelect-icon': { color: 'white' },
											width: '100%',
											height: '32px',
											'& .MuiOutlinedInput-root': {
												height: '32px',
												'& .MuiSelect-select': {
													padding: '4px 8px',
													fontSize: '0.8rem',
												},
											},
										}}>
										<MenuItem value="" disabled>
											Producto
										</MenuItem>
										{['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'Vida', 'Hogar', 'Negocio', 'RC'].map((prod, i) => (
											<MenuItem key={i} value={prod}>
												{prod}
											</MenuItem>
										))}
									</Select>
									{errors[`productName_${index}`] && (
										<Typography variant="caption" color="error">
											{errors[`productName_${index}`]}
										</Typography>
									)}
								</Box>
							</Grid>

							<Grid item xs={12} md={8}>
								<Box sx={{ marginTop: -0.7 }}>
									<Typography variant="body2" sx={{ color: 'white' }}>
										Imágenes
									</Typography>
									<Grid container spacing={0.5}>
										{['Frontal', 'Lateral izq', 'Trasera', 'Lateral der', 'R.Auxilio', 'Tablero'].map((docRef) => {
											const key = `${index}-${docRef}`;
											return (
												<Grid item xs={6} key={docRef}>
													<Typography variant="caption" sx={{ color: 'white' }}>
														{docRef}
													</Typography>
													<Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', maxHeight: '30px' }}>
														<input
															ref={(el) => (inputRefs.current[key] = el)}
															type="file"
															accept="image/*"
															style={{
																backgroundColor: '#333',
																padding: '4px',
																width: '100%',
															}}
															onChange={(e) => handleFileChange(Array.from(e.target.files!), docRef, veh.plateNumber, index)}
														/>
														{filePreviews[key] && (
															<Box sx={{ ml: 2, position: 'relative' }}>
																<img
																	src={filePreviews[key]}
																	alt="Vista previa"
																	style={{
																		width: '40px',
																		height: '40px',
																		objectFit: 'cover',
																		borderRadius: '8px',
																		border: '1px solid #ddd',
																	}}
																/>
																{/* <IconButton
																	size="small"
																	onClick={() => clearPreview(key)}
																	sx={{
																		position: 'absolute',
																		top: 5,
																		right: -30,
																		padding: '2px',
																		color: '#b8a6a6',

																	}}
																>
																	<DeleteIcon fontSize="small" />
																</IconButton> */}
															</Box>
														)}
													</Box>
												</Grid>
											);
										})}
									</Grid>
								</Box>
								<Box>
									<Typography variant="body2" sx={{ color: 'white', mb: 1, mt: 2 }}>
										Documentos
									</Typography>
									<Grid container spacing={1}>
										<Grid item xs={6}>
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												<Typography variant="caption" sx={{ color: 'white' }}>
													Tarjeta verde
												</Typography>
												<input
													type="file"
													accept=".pdf,.doc,.docx"
													style={{
														backgroundColor: '#333',
														padding: '4px',
														width: '100%',
													}}
													onChange={(e) => handleFileChange(Array.from(e.target.files!), 'Tarjeta verde', veh.plateNumber, index)}
												/>
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box sx={{ display: 'flex', flexDirection: 'column' }}>
												<Typography variant="caption" sx={{ color: 'white' }}>
													Otros
												</Typography>
												<input
													type="file"
													accept=".pdf,.doc,.docx"
													style={{
														backgroundColor: '#333',
														padding: '4px',
														width: '100%',
													}}
													onChange={(e) => handleFileChange(Array.from(e.target.files!), 'otros', veh.plateNumber, index)}
												/>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Box sx={{ mt: 2 }}>
							<StyledTextField
								fullWidth
								multiline
								rows={3}
								label="Descripción del producto de seguro"
								value={veh.insuranceProduct.description}
								onChange={(e) => handleVehicleChange(index, 'description', e.target.value)}
							/>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};
