import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Badge, Box, Button, Card, CardContent, CircularProgress, Grid, Snackbar, ThemeProvider, Tooltip, Typography, createTheme } from '@mui/material';
import { UUID } from 'crypto';
import { getProductByOwner, updateProductStatusByID, deleteProductByID } from '../../../api/productsApi';
import { INewProduct, IProduct } from '../../../api/interfaces/interfaces';
import { NewProduct } from '../../products/newProduct/NewProduct';
import { ManagementPagination } from './ManagementPagination';
import { ActionButtons } from './ActionButtons';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { currencyFormatter } from '../../../functions/CommonFunctions';
import { NoProductAvailable } from './NoProductAvailable';
import { useNavigate } from 'react-router-dom';
import { getCommetsCountWithoutResponseByProductOwner } from '../../../api/commentsApi';

export const ProductManagement: React.FC<{}> = () => {
	const [products, setProducts] = useState<IProduct[]>([]);
	const [currentProducts, setCurrentProducts] = useState<IProduct[]>([]);
	const [productEdit, setProductEdit] = useState<IProduct | undefined>();
	const [loading, setLoading] = useState<boolean>(true);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [selectedPage, setSelectedPage] = useState<number>(1);
	const [showEditView, setShowEditView] = useState<boolean>(false);
	const [showInactiveProducts, setShowInactiveProducts] = useState<boolean>(true);
	const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
	const [countQuestions, setCountQuestions] = useState<number>(0);
	// const [alert, setAlert] = useState<IAlert>({
	// 	type: 'info',
	// 	message: '',
	// 	show: false,
	// });
	const [notification, setNotifation] = useState(false);
	const [errorNotification, setErrorNotifation] = useState(false);
	const [hidingNotification, setHidingNotifation] = useState(false);
	const closePopUp = () => {
		setNotifation(false);
		setErrorNotifation(false);
		setHidingNotifation(false);
	};
	const theme = createTheme({
		palette: {
			success: {
				main: '#66BB6A', // Color principal personalizado para success
				contrastText: '#fff', // Color del texto en el botón de éxito
			},
		},
	});
	const navigate = useNavigate();

	const loadProducts = async () => {
		setLoading(true);
		const productsData = await getProductByOwner(10, selectedPage);
		const { products, totalPages } = productsData;
		setProducts(products);
		setCurrentProducts(products);
		setTotalPages(totalPages);
		setLoading(false);
	};
	const loadCountQuestions = async () => {
		const count = await getCommetsCountWithoutResponseByProductOwner();
		if (count !== countQuestions) setCountQuestions(count);
	};
	const handleShowProducts = useCallback(() => {
		if (showInactiveProducts) {
			setCurrentProducts(products.filter((elem) => elem.status === 'Activo'));
		} else setCurrentProducts(products);
		setShowInactiveProducts(!showInactiveProducts);
	}, [showInactiveProducts, setCurrentProducts, products]);

	const handleEditProduct = (id: string) => {
		setProductEdit(products.find((p) => p.id === id));
		setShowEditView(true);
	};

	const handleChangeStatusProduct = async (id: UUID, status: string) => {
		await updateProductStatusByID(id, status === 'Activo' ? 'Inactivo' : 'Activo');
		setHidingNotifation(true);
		loadProducts();
	};

	const onConfirmDialog = () => {
		deleteProduct(selectedProduct!);
	};

	const deleteProduct = useCallback(
		async (product: IProduct) => {
			const rta = await deleteProductByID(product.id!);
			if (rta.status === 204) {
				const nuevoArray = [...products];
				const index = nuevoArray.findIndex((p) => p.id === product.id);
				nuevoArray.splice(index, 1);
				setProducts(nuevoArray);
				setNotifation(true);
		loadProducts();
			} else
				setErrorNotifation(true);
		},
		[products],
	);

	const handlePageClick = (index: number) => {
		setSelectedPage(index);
	};

	const handleNext = useCallback(() => {
		if (selectedPage < totalPages) handlePageClick(selectedPage + 1);
	}, [totalPages, selectedPage]);

	const handleBack = useCallback(() => {
		if (selectedPage > 1) handlePageClick(selectedPage - 1);
	}, [selectedPage]);

	useEffect(() => {
		if (window.location.pathname === '/login') {
			return;
		}
		//Esto implementado de esta forma evita multiples peticiones por re-render.
		loadCountQuestions();
		const intervalId = setInterval(() => loadCountQuestions(), 60000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		if (!showEditView) loadProducts();
	}, [showEditView]);

	return (
		<>
			{!showEditView && (
				<Grid
					container
					spacing={8}
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: '100vh', backgroundColor: '#111111' }}>
					<Grid item xs={12} sm={8} md={6} lg={7}>
						<Card
							style={{
								backgroundColor: '#111111',
								minHeight: '70vh',
								maxHeight: '70vh',
								textAlign: 'center',
								overflow: 'auto',
								borderTop: 'thin solid #717171',
								borderRadius: '26px',
								borderLeft: 'thin solid #717171',
								borderRight: 'thin solid #717171',
								borderBottom: 'thin solid #717171',
							}}>
							<CardContent>
								<Card
									style={{
										backgroundColor: '#111111',
										marginBottom: '1rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										position: 'sticky',
										zIndex: '90',
										top: 0,
									}}>
									{/* <IconButton
									aria-label="Back"
									sx={{
										border: '.1rem solid white',
										borderRadius: '10px',
										backgroundColor: '#283593',
										'&:hover': { backgroundColor: '#1a237e' },
										ml: '1rem',
									}}
									size="small"
									onClick={() => onClose(true)}>
									<ArrowBackIcon sx={{ color: 'white' }} />
								</IconButton> */}
									<Typography
										sx={{
											marginTop: '.5rem',
											// marginLeft: '1rem',
											color: '#fffff4',
											fontFamily: 'Roboto',
											fontSize: { md: '1.5rem' },
										}}
										gutterBottom>
										Administrar publicaciones
									</Typography>
									{products.length > 0 && (
										<Button
											onClick={() => {
												handleShowProducts();
											}}
											sx={{
												borderBottom: 'thin solid red',
												'&:hover': { backgroundColor: '#71717171' },
												height: { xs: 'auto', sm: 'auto', md: '1.5rem' },
												fontSize: { xs: '.5rem', md: '.7rem' },
												backgroundColor: '#111111',
											}}
											size="small"
											variant="contained">
											{showInactiveProducts ? 'Ocultar inactivos' : 'Mostrar inactivos'}
										</Button>
									)}
								</Card>
								{loading && (
									<Grid
										style={{
											position: 'fixed',
											top: '50%',
											left: '50%',
											transform: 'translate(-50%, -50%)',
										}}>
										<CircularProgress size={60} color="error" />
									</Grid>
								)}

								{!loading && !showEditView && products && (
									<Grid container spacing={3}>
										{currentProducts.map((product, index) => {
											return (
												<Grid key={index} item xs={12} sx={{ padding: '0.1rem', height: 'fit-content' }}>
													<Card
														sx={{
															height: '16vh',
															backgroundColor: '#111111',
															borderBottom: '1px inset #B71C1C',
															mt: '-1rem',
															display: 'flex',
															alignItems: 'center',
															padding: '0.5rem',
															position: 'relative', // Añadido para posicionar correctamente el botón
														}}>
														{/* Imagen con border radius */}
														<Button
															onClick={() => navigate(`/product-detail/${product.id}`)}
															sx={{ width: { xs: '115%', md: '45%', lg: 'fit-content' }, justifyContent: 'left' }}>
															<Grid
																sx={{
																	borderRadius: '22%',
																	overflow: 'hidden',
																	maxWidth: '30%',
																	maxHeight: '100%',
																	marginRight: '0.5rem',
																}}>
																<Box
																	component='img'
																	src={
																		product.images && product.images.length > 0 && product.images[0].imageData !== null
																			? product.images[0].imageData
																			: ''
																	}
																	alt="Imagen"
																	sx={{
																		marginBottom: {xs: 0,sm:'3.2rem'}, width: '100%', height: '100%', padding: { xs: 0, sm: 1.5 }, objectFit: 'cover',
																	}}
																/>
															</Grid>

															{/* TextFields */}

															<Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
																<Typography
																	sx={{
																		color: 'white',
																		marginTop: '.6rem',
																		marginBottom: '.5rem',
																		whiteSpace: 'nowrap',
																		overflow: 'hidden',
																		textOverflow: 'ellipsis',
																		fontFamily: 'arial',
																		fontSize: '.8rem',
																		textAlign: 'left',
																		width: '100%',
																	}}
																	key={index + 'ty2'}
																	variant="button">
																	{product.name}
																</Typography>
																<Typography
																	sx={{
																		textAlign: 'left',
																		color: 'white',
																		fontFamily: 'arial',
																		marginBottom: '.5rem',
																		fontSize: '.8rem',
																	}}
																	variant="button"
																	key={index + 'ty3'}>
																	${currencyFormatter({ value: product.price })}
																</Typography>
																<Typography
																	sx={{
																		width: { xs: '55%', md: '100%' },
																		color: 'white',
																		fontFamily: 'arial',
																		marginBottom: '.5rem',
																		fontSize: '.8rem',
																		textAlign: 'left',
																	}}
																	key={index + 'ty4'}
																	variant="button">
																	{product.condition}
																</Typography>
															</Grid>
														</Button>
														{/* campana notificaciones */}
														<Grid sx={{ position: 'absolute', top: '.3rem', right: '2.5rem' }}>
															<Tooltip title="Revisar preguntas" placement="left">
																<Button
																	onClick={() => navigate('/manage-asks')}
																	style={{
																		backgroundColor: '#111111',
																		minWidth: 'max-content',
																		marginLeft: '-2rem',
																	}}
																	size="small">
																	<Badge badgeContent={countQuestions} color="error" showZero={false} variant="dot" max={99}>

																		<NotificationsIcon
																			sx={{ mb: '1px', color: 'white', backgroundColor: '#111111' }}
																			fontSize="small"
																		/>
																	</Badge>
																</Button>
															</Tooltip>
														</Grid>

														{/* botón de estado */}
														<Grid sx={{ position: 'absolute', top: '.3rem', right: '1.2rem' }}>
															<Tooltip title="Estado del producto" placement="right">
																<Badge
																	color={product.status === 'Activo' ? 'success' : 'error'}
																	overlap="circular"
																	variant="dot"
																	slotProps={{
																		badge: { style: { height: '14px', width: '14px', borderRadius: '16px' } },
																	}}
																	badgeContent=" "></Badge>
															</Tooltip>
														</Grid>
														{/* Botón */}
														<Grid sx={{ position: 'absolute', bottom: '.6rem', right: '1rem' }}>
															<ActionButtons
																handleChangeStatusProduct={handleChangeStatusProduct}
																handleEditProduct={handleEditProduct}
																onConfirmDialog={onConfirmDialog}
																product={product}
																setSelectedProduct={setSelectedProduct}
															/>
														</Grid>
													</Card>
												</Grid>
											);
										})}
									</Grid>
								)}
								{!loading && products.length === 0 && <NoProductAvailable />}
							</CardContent>
						</Card>
						{totalPages > 1 &&
							<ManagementPagination
								handleBack={handleBack}
								handleNext={handleNext}
								handlePageClick={handlePageClick}
								selectedPage={selectedPage}
								totalPages={totalPages}
							// products={products}
							// cantElements={products.length}
							// elementsPerPage={5}
							// setCurrentProducts={setCurrentProducts}
							/>
						}
					</Grid>
					<ThemeProvider theme={theme}>
						<Snackbar
							open={notification}
							autoHideDuration={4000}
							onClose={closePopUp}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Alert
								onClose={closePopUp}
								severity="success"
								variant="filled"
								sx={{ width: { xs: '80%', sm: '100%' }, mt: '2.8rem' }}
							>
								Publicación eliminada con éxito
							</Alert>
						</Snackbar>
						<Snackbar
							open={errorNotification}
							autoHideDuration={4000}
							onClose={closePopUp}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Alert
								onClose={closePopUp}
								severity="error"
								variant="filled"
								sx={{ width: { xs: '80%', sm: '100%' }, mt: '2.8rem' }}
							>
								Ha ocurrido un error!
							</Alert>
						</Snackbar>
						<Snackbar
							open={hidingNotification}
							autoHideDuration={4000}
							onClose={closePopUp}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Alert
								onClose={closePopUp}
								severity="info"
								variant="filled"
								sx={{ width: { xs: '80%', sm: '100%' }, mt: '2.8rem' }}
							>
								Publicación mostrada/ocultada con éxito
							</Alert>
						</Snackbar>
					</ThemeProvider>
				</Grid>
			)}
			{showEditView && (
				<NewProduct
					editedProductId={productEdit?.id}
					editedProduct={productEdit as INewProduct}
					setShowComponent={setShowEditView}
				/>
			)}
		</>
	);
};
