import { Button, Step, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Accident, CreateAccidentData, Insured, InsuredVehicle, NewDocumentImage } from '../../../../api/interfaces/interfaces';
import { createAccident, getAllAccidentsByOwner, getAllInsuredVehicles, getInsuredBySearch } from '../../../../api/insuredApi';
import { saveDocuments } from '../../../../api/documentsApi';
import { DocumentsStep } from './CreateAccidentSteps/DocumentsStep';
import { AccidentInfoStep } from './CreateAccidentSteps/AccidentInfoStep';

interface CreateAccidentProps {
	handleClose: () => void;
	setAccidentList: React.Dispatch<React.SetStateAction<Accident[]>>;
}

const steps = ['Documentos', 'Información del siniestro'];

export const CreateAccidentStepper = (props: CreateAccidentProps) => {
	const [formData, setFormData] = useState<CreateAccidentData>({
		accidentNumber: '',
		description: '',
		insured: '',
		insuredVehicleId: '',
		accidentDate: new Date(),
		insuredVehicleBrand: 'test',
		insuredVehicleModel: 'test',
		insuredVehicleYear: 2000,
		plateNumber: 'aaa',
	});
	const [activeStep, setActiveStep] = useState(0);
	const { handleClose, setAccidentList } = props;
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [insured, setInsured] = useState<Insured[]>([]);
	const [vehicles, setVehicles] = useState<InsuredVehicle[]>([]);
	const [documents, setDocuments] = useState<NewDocumentImage[]>([]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleInsuredChange = async (value: Insured) => {
		setFormData((prev) => ({ ...prev, insured: value.id }));
		const vehicles = await getAllInsuredVehicles(value.id);
		setVehicles(vehicles || []);
	};
	const addImages = (reader: FileReader, file: File, blob: Blob, docReference: string, plateNumber: string) => {
		setDocuments((prevFiles) => [
			...prevFiles,
			{
				name: file.name,
				imageData: reader.result?.toString()!,
				mimeType: blob.type,
				imageSide: blob.size?.toString(),
				documentReference: docReference ?? 'otros',
				ownerInsuredID: null,
				ownerInsuredVehicleID: null,
				ownerAccidentID: null,
				plateNumber: plateNumber || 'aaa',
			},
		]);
	};

	const convertFileToBlob = (file: File): Promise<Blob> => {
		const blob = file.arrayBuffer().then((arrayBuffer) => new Blob([new Uint8Array(arrayBuffer)], { type: file.type }));
		return blob;
	};

	const handleFileChange = async (files: File[], docReference: string, plateNumber: string | null) => {
		try {
			await Promise.all(
				files.map(async (file) => {
					const blob = await convertFileToBlob(file);

					const reader = new FileReader();

					const imageDataPromise = new Promise<string | ArrayBuffer>((resolve, reject) => {
						reader.onloadend = () => resolve(reader.result as string | ArrayBuffer);
						reader.onerror = reject;
					});

					reader.readAsDataURL(blob);
					await imageDataPromise;

					addImages(reader, file, blob, docReference, plateNumber || '');
				}),
			);
		} catch (error) {
			console.error('Error al procesar las imágenes:', error);
		}
	};

	const validateForm = (): boolean => {
		const newErrors: { [key: string]: string } = {};

		if (!formData.accidentNumber) {
			newErrors.nroDenuncia = 'El número de denuncia es requerido.';
		}
		if (!formData.description) {
			newErrors.descripcion = 'La descripción es requerida.';
		}
		if (!formData.insured) {
			newErrors.asegurado = 'El asegurado es requerido.';
		}
		// if (!formData.vehicle) {
		// 	newErrors.asegurado = 'El vehículo es requerido.';
		// }

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};
	const searchInsured = async (value: string) => {
		const insured = await getInsuredBySearch(value);
		setInsured(insured ?? []);
	};
	const handleSubmit = async () => {
		if (validateForm()) {
			//Mostrar spinner con mensaje: Guardado siniestro
			await createAccident(formData)
				.then(async (data) => {
					if (data) {
						if (documents.length > 0) {
							//cambiar el mensaje del spinner a: Guardando archivos
							documents.forEach((doc, index) => {
								documents[index].ownerAccidentID = data;
							});

							await saveDocuments(documents).then((data) => {
								if (data === 201) alert('documentos guardos correctamente');
								else alert('error al guardar documentos');
							});
						}
						alert('Accidente creado exitosamente');
						handleClose();
					} else alert('Hubo un error al crear el accidente.');
				})
				.finally(async () => setAccidentList((await getAllAccidentsByOwner()) || []));
		}
	};

	const handleNext = () => {
		if (validateForm()) {
			if (activeStep === steps.length - 1) {
				handleSubmit();
			} else {
				setActiveStep((prev) => prev + 1);
			}
		} else {
			alert('Por favor, corrige los errores en el formulario antes de continuar.');
		}
	};
	const handleBack = () => {
		setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
	};

	return (
		<Box
			sx={{
				backgroundColor: 'black',
				minWidth: 550,
				display: 'flex',
				flexDirection: 'column',
			}}>
			<Stepper
				activeStep={activeStep}
				alternativeLabel
				sx={{
					backgroundColor: 'black',
					'& .MuiStepLabel-root': { color: 'white' },
					'& .MuiStepLabel-label': { color: 'white' },
					'& .MuiStepLabel-label.Mui-active': { color: 'white' },
					'& .MuiStepLabel-label.Mui-completed': { color: 'white' },
					'& .Mui-disabled .MuiStepLabel-label': {
						color: 'rgba(255, 255, 255, 0.5)',
					},
				}}>
				{steps.map((label, index) => (
					<Step key={index}>
						<StepLabel sx={{ color: 'white' }}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			<Box sx={{ marginTop: 2, flex: 1 }}>
				{activeStep === 0 && (
					<AccidentInfoStep
						formData={formData}
						errors={errors}
						handleInputChange={handleInputChange}
						insured={insured}
						vehicles={vehicles}
						handleInsuredChange={handleInsuredChange}
						setFormData={setFormData}
						searchInsured={searchInsured}
					/>
				)}
				{activeStep === 1 && <DocumentsStep handleFileChange={handleFileChange} />}
			</Box>

			<Box sx={{ marginTop: 2 }}>
				<Button
					sx={{
						backgroundColor: '#a70d15',
						color: 'white',
						'&:hover': { backgroundColor: '#8a1218' },
					}}
					onClick={handleBack}
					disabled={activeStep === 0}
					variant="contained">
					Atrás
				</Button>
				<Button
					sx={{
						marginLeft: 2,
						backgroundColor: '#a70d15',
						color: 'white',
						'&:hover': { backgroundColor: '#8a1218' },
					}}
					onClick={handleNext}
					variant="contained">
					{activeStep === steps.length - 1 ? 'Crear siniestro' : 'Siguiente'}
				</Button>
			</Box>
		</Box>
	);
};
