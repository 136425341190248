import { axiosService } from './axiosService';
import { IState } from './interfaces/interfaces';
export const getArgentinianStates = async (fields: string): Promise<IState[]> => {
	const data = await axiosService.get(`geoLocations/states/${fields}`).then(
		(response) => {
			const { data, status } = response;
			if (status === 200) return data.provincias;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};

export const getMunicipalitiesByState = async (stateID: string): Promise<IState[]> => {
	const data = await axiosService.get(`geoLocations/municipalitiesByState/${stateID}`).then(
		(response) => {
			const { data, status } = response;
			if (status === 200) return data.municipios;
			return [];
		},
		(err) => {
			console.error('Error: ' + err);
			return [];
		},
	);
	return data;
};
