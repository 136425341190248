import { Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { CreateInsuredData } from "../../../../../api/interfaces/interfaces";
import styled from '@emotion/styled';

interface PersonalStepProps {
    formData: CreateInsuredData;
    errors: { [key: string]: string };
    handleTextFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (e: SelectChangeEvent<string>) => void;
}

const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputLabel-root': {
        color: 'white',
        '&.Mui-focused': {
            color: 'white',
        },
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'black',
        color: 'white',
        '& fieldset': {
            borderColor: '#9e1616',
        },
        '&:hover fieldset': {
            borderColor: '#9e1616',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#9e1616',
        },
    },
}));


export const PersonalStep = (props: PersonalStepProps) => {
    const { formData, errors, handleTextFieldChange,  handleSelectChange,} = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <StyledTextField
                    fullWidth
                    label="Nombre"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleTextFieldChange}
                    size="small"
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                />
            </Grid>
            <Grid item xs={6}>
                <StyledTextField
                    fullWidth
                    label="Apellido"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleTextFieldChange}
                    size="small"
                    error={!!errors.lastname}
                    helperText={errors.lastname}
                />
            </Grid>
            <Grid item xs={6}>
                <StyledTextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleTextFieldChange}
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    fullWidth
                    name="personType"
                    value={formData.personType}
                    onChange={handleSelectChange}
                    size="small"
                    displayEmpty
                    error={!!errors.personType}
                    sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        border: '1px solid #9e1616',
                        '.MuiSelect-icon': { color: 'white' },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: 'black',
                                '& .MuiMenuItem-root': {
                                    color: 'white',
                                    '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.3)' },
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value="" disabled>
                        Tipo de Persona
                    </MenuItem>
                    <MenuItem value="Fisica">Física</MenuItem>
                    <MenuItem value="Juridica">Jurídica</MenuItem>
                </Select>
                {errors.personType && (
                    <Typography variant="caption" color="error">
                        {errors.personType}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={6}>
                <Select
                    fullWidth
                    name="docType"
                    value={formData.docType}
                    size="small"
                    onChange={handleSelectChange}
                    displayEmpty
                    error={!!errors.docType}
                    sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        border: '1px solid #9e1616',
                        '.MuiSelect-icon': { color: 'white' },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: 'black',
                                '& .MuiMenuItem-root': {
                                    color: 'white',
                                    '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.3)' },
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value="" disabled>
                        Tipo de Documento
                    </MenuItem>
                    <MenuItem value="DNI">DNI</MenuItem>
                    <MenuItem value="CUIT">CUIT</MenuItem>
                </Select>
                {errors.docType && (
                    <Typography variant="caption" color="error">
                        {errors.docType}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={6}>
                <StyledTextField
                    fullWidth
                    label="DNI / CUIT"
                    size="small"
                    name="cuit"
                    value={formData.cuit}
                    onChange={handleTextFieldChange}
                    error={!!errors.cuit}
                    helperText={errors.cuit}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    fullWidth
                    name="maritalStatus"
                    value={formData.maritalStatus}
                    onChange={handleSelectChange}
                    size="small"
                    displayEmpty
                    sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        border: '1px solid #9e1616',
                        '.MuiSelect-icon': { color: 'white' },
                    }}
                >
                    <MenuItem value="" disabled>
                        Estado civil
                    </MenuItem>
                    <MenuItem value="Soltero">Soltero</MenuItem>
                    <MenuItem value="Casado">Casado</MenuItem>
                    <MenuItem value="Divorciado">Divorciado</MenuItem>
                    <MenuItem value="Viudo">Viudo</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={6}>
                <StyledTextField
                    fullWidth
                    size="small"
                    label="Nacionalidad"
                    name="nacionality"
                    value={formData.nacionality}
                    onChange={handleTextFieldChange}
                    error={!!errors.nacionality}
                    helperText={errors.nacionality}
                />
            </Grid>
            <Grid item xs={6}>
                <StyledTextField
                    fullWidth
                    type="date"
                    label="Fecha de Nacimiento"
                    size="small"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleTextFieldChange}
                    error={!!errors.birthday}
                    helperText={errors.birthday}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        </Grid>
    );
};