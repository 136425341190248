import { getVehicleModelsByBrand } from '../../../../api/modelsApi';
import { getVehicleBrands } from '../../../../api/brandsApi';
import { getCategoriesLeaves } from '../../../../api/categoriesApi';
import { useEffect, useState } from 'react';
import { ICategory, INewImage, INewProduct, IVehicleBrand, IVehicleModel } from '../../../../api/interfaces/interfaces';
import {
	Autocomplete,
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import styled from '@emotion/styled';
interface StepTwoProps {
	handleChangeForm: (fieldName: keyof INewProduct, value: string | number | INewImage[] | string[] | null) => void;
	newProduct: INewProduct;
}

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInputLabel-root': {
		color: 'white',
		'&.Mui-focused': {
			color: 'white',
		},
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: 'black',
		color: 'white',
		borderRadius: '10px',
		'& fieldset': {
			borderColor: '#9e1616',
		},
		'&:hover fieldset': {
			borderColor: '#9e1616',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#9e1616',
		},
	},
	'& .MuiSelect-select': {
		borderRadius: '10px',
	},
}));


export const StepThree = (props: StepTwoProps) => {
	const { handleChangeForm, newProduct } = props;
	const [isLoadingCategories, setLoadingCategories] = useState<boolean>(false);
	const [isLoadingBrands, setLoadingBrands] = useState<boolean>(false);
	const [isLoadingModels, setLoadingModels] = useState<boolean>(false);
	const [categories, setCategories] = useState<ICategory[]>([]);
	const [brands, setBrands] = useState<IVehicleBrand[]>([]);
	const [models, setModels] = useState<IVehicleModel[]>([]);
	const [years, setYears] = useState<string[]>([]);
	const linesOptions = ['Linea unica', 'Linea nueva', 'Linea vieja'];
	const loadCategories = async () =>
		await getCategoriesLeaves().then((cat) => {
			const sortedCategories = cat?.sort((a, b) => a.name.localeCompare(b.name));
			setCategories(sortedCategories);
			setLoadingCategories(false);
		});
	const loadBrands = async () =>
		await getVehicleBrands().then((cat) => {
			setBrands(cat);
			setLoadingBrands(false);
		});
	const loadModels = async (id: string) =>
		await getVehicleModelsByBrand(id).then((cat) => {
			setModels(cat);
			setLoadingModels(false);
		});
	const onBrandChange = (brandID: string) => {
		handleChangeForm('vehicleBrandID', brandID);
		handleChangeForm('vehicleModelID', null);
		brandID && loadModels(brandID);
	};
	const initialzeYears = () => {
		let years = [];
		const actualYear = new Date().getFullYear();
		for (var i = actualYear; i >= 1900; i--) years.push(i.toString());
		setYears(years);
	};

	useEffect(() => {
		setLoadingCategories(true);
		setLoadingBrands(true);
		setLoadingModels(true);
		initialzeYears();
		loadCategories();
		loadBrands();
		if (newProduct.vehicleBrandID) loadModels(newProduct.vehicleBrandID);
	}, []);

	return (
		<Grid
			item
			xs={12}
			sx={{
				width: '100%', // O un valor que se adecúe a lo que necesitas
			}}>


			<FormControl fullWidth sx={{ minWidth: 120 }} style={{ marginTop: '2%' }}>
				<Autocomplete
					// disablePortal
					id="combo-box-demo"
					options={categories}
					getOptionLabel={(category) => category.name}
					value={categories?.find((category) => category.id === newProduct.categoryID) || null}
					onChange={(event, newValue) => handleChangeForm('categoryID', newValue?.id!)}
					disabled={isLoadingCategories}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							label="Categoría *"
							// InputProps={{style: {borderRadius: '10px'}}}
							InputProps={{
								...params.InputProps,
								style: { borderRadius: '10px' },
								endAdornment: (
									<>
										{isLoadingCategories ? <CircularProgress size={24} color="inherit" /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
					sx={{ marginBottom: '2%' }}
				/>
				<Autocomplete
					id="combo-box-demo"
					options={brands}
					getOptionLabel={(brand) => brand.name}
					value={brands?.find((brand) => brand.id === newProduct.vehicleBrandID) || null}
					onChange={(event, newValue) => onBrandChange(newValue?.id!)}
					disabled={isLoadingBrands}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							label="Marca del repuesto"
							InputProps={{
								...params.InputProps,
								style: { borderRadius: '10px' },
								endAdornment: (
									<>
										{isLoadingBrands ? <CircularProgress size={24} color="inherit" /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
					sx={{ marginBottom: '2%' }}
				/>
				<Autocomplete
					id="combo-box-demo"
					options={models}
					getOptionLabel={(model) => model.name}
					value={models?.find((model) => model.id === newProduct.vehicleModelID) || null}
					onChange={(event, newValue) => handleChangeForm('vehicleModelID', newValue?.id!)}
					// disabled={isLoadingModels}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							label="Modelo del repuesto"
							InputProps={{
								...params.InputProps,
								style: { borderRadius: '10px' },
								endAdornment: (
									<>
										{isLoadingModels ? <CircularProgress size={24} color="inherit" /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
					sx={{ marginBottom: '2%' }}
				/>
				{newProduct.vehicleModelID === models?.find((model) => model.name === 'Otro')?.id && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							mb: '3%',
						}}
					>
						<Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: { xs: 5 },
								transform: 'translateY(-50%)',
								width: 0,
								height: 0,
								borderStyle: 'solid',
								borderWidth: '5px 0 5px 5px',
								borderColor: 'transparent transparent transparent red',
								zIndex: 1,
							}}
						/>
						<StyledTextField
							label="Especificar"
							fullWidth
							sx={{
								maxWidth: '500px',
							}}
							onChange={({ target: { value } }) => handleChangeForm('specificVehicleModel', value)}
							defaultValue={newProduct.specificVehicleModel || ''}
						/>
					</Box>
				)}

				<StyledTextField
					select
					fullWidth
					label="Años compatibles del repuesto"
					SelectProps={{
						multiple: true,
						renderValue: (selected) => (selected as string[]).join(', '),
					}}
					value={newProduct.yearVehicleModel?.split(',') ?? []}
					onChange={({ target }) => {
						const selectedValues = Array.isArray(target.value) ? target.value : [target.value];
						handleChangeForm('yearVehicleModel', selectedValues.join(','));
					}}

					sx={{ marginBottom: '2%' }}
				>
					{years.map((year) => (
						<MenuItem key={year} value={year}>
							<Checkbox checked={newProduct?.yearVehicleModel?.split(',').indexOf(year)! > -1} />
							<ListItemText primary={year} />
						</MenuItem>
					))}
				</StyledTextField>

				<StyledTextField
					select
					fullWidth
					label="Línea del modelo"
					value={newProduct.vehicleModelLine}
					onChange={({ target: { value } }) => handleChangeForm('vehicleModelLine', value)}
				>
					{linesOptions.map((opt, index) => (
						<MenuItem value={opt} key={index}>
							{opt}
						</MenuItem>
					))}
				</StyledTextField>

			</FormControl>
		</Grid>
	);
};
