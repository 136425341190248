import {
	Insured,
	CreateInsuredData,
	Accident,
	CreateAccidentData,
	ResponseNewInsured,
	InsuredVehicle,
	InsuranceCompany,
	BillingMail,
} from './interfaces/interfaces';
import { axiosService } from './axiosService';

//// ASEGURADOS

export const getAllInsuredByOwner = async (limit: number = 10, offset: number = 1): Promise<Insured[]> => {
	try {
		const response = await axiosService.get(`/insured/insuredByOwner`, { params: { limit, offset } });
		if (response.status === 200) {
			return response.data as Insured[];
		} else {
			console.warn('Unexpected response status:', response.status);
			return [];
		}
	} catch (error) {
		console.error('Error fetching insured data:', error);
		return [];
	}
};

export const createInsured = async (insuredData: CreateInsuredData): Promise<ResponseNewInsured | null> => {
	try {
		const response = await axiosService.post(`/insured/createNewInsured`, insuredData);
		if (response.status === 201) {
			return { ...response.data, status: response.status };
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error creating insured:', error);
		return null;
	}
};

export const getInsuredById = async (id: string): Promise<Insured | null> => {
	try {
		const response = await axiosService.get(`/insured/insuredById/${id}`);
		if (response.status === 200) {
			return response.data as Insured;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by ID:', error);
		return null;
	}
};

export const getInsuredBySearch = async (
	search: string,
	limit: number = 10,
	offset: number = 1,
): Promise<Insured[] | null> => {
	try {
		const response = await axiosService.get(`/insured/insuredBySearch/${search}`, {
			params: { limit, offset },
		});
		if (response.status === 200) {
			return response.data as Insured[];
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by search:', error);
		return null;
	}
};

export const deleteInsuredById = async (id: string): Promise<Insured | null> => {
	try {
		const response = await axiosService.delete(`/insured/deleteInsuredById/${id}`);
		if (response.status === 200) {
			return response.data as Insured;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error deleting insured by ID:', error);
		return null;
	}
};
//////// VEHICULOS
export const getAllInsuredVehicles = async (id: string): Promise<InsuredVehicle[] | null> => {
	try {
		const response = await axiosService.get(`/insuredVehicle/insuredVehicleByOwner/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by ID:', error);
		return null;
	}
};

export const getInsuredVehicle = async (id: string): Promise<InsuredVehicle | null> => {
	try {
		const response = await axiosService.get(`/insuredVehicle/insuredVehicleById/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by ID:', error);
		return null;
	}
};
//////// COMPANIES
export const getAllInsuranceCompanies = async (): Promise<InsuranceCompany[] | null> => {
	try {
		const response = await axiosService.get(`/insuranceCompany`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by ID:', error);
		return null;
	}
};
export const getInsuranceCompanyDataByName = async (name: string = ''): Promise<InsuranceCompany | null> => {
	try {
		const response = await axiosService.get(`/insuranceCompany/insuranceCompanyByName/${name}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching insured by name:', error);
		return null;
	}
};

//////// ACCIDENTES

export const getAllAccidentsByOwner = async (limit: number = 10, offset: number = 1): Promise<Accident[]> => {
	try {
		const response = await axiosService.get('/accidents/allAccidentsByOwner', { params: { limit, offset } });
		if (response.status === 200) {
			return response.data as Accident[];
		} else {
			console.warn('Unexpected response status:', response.status);
			return [];
		}
	} catch (error) {
		console.error('Error fetching accidents:', error);
		return [];
	}
};

export const createAccident = async (accidentData: CreateAccidentData): Promise<string | null> => {
	try {
		const response = await axiosService.post('/accidents/createNewAccident', accidentData);
		if (response.status === 201) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error creating accident:', error);
		return null;
	}
};

export const getAccidentById = async (id: string): Promise<Accident | null> => {
	try {
		const response = await axiosService.get(`/accidents/accidentById/${id}`);
		if (response.status === 200) {
			return response.data as Accident;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching accident by ID:', error);
		return null;
	}
};
export const getAllBilledAccidents = async (limit: number = 20, offset: number = 0): Promise<Accident[] | null> => {
	try {
		const response = await axiosService.get(`/accidents/billedAccidents?limit=${limit}&offset=${offset}`);
		if (response.status === 200) {
			return response.data;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching accident by ID:', error);
		return null;
	}
};

export const updateAccidentById = async (
	id: string,
	accidentData: Partial<CreateAccidentData>,
): Promise<Accident | null> => {
	try {
		const response = await axiosService.patch(`/accidents/updateUserById/${id}`, accidentData);
		if (response.status === 200) {
			return response.data as Accident;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error updating accident by ID:', error);
		return null;
	}
};
export const billAccident = async (billingData: BillingMail): Promise<any> => {
	try {
		const response = await axiosService.patch(`/accidents/billAccident`, billingData);
		if (response.status === 201) {
			return response;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error updating accident by ID:', error);
		return null;
	}
};

export const deleteAccidentById = async (id: string): Promise<Accident | null> => {
	try {
		const response = await axiosService.delete(`/accidents/deleteAccidentById/${id}`);
		if (response.status === 200) {
			return response.data as Accident;
		} else {
			console.warn('Unexpected response status:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error fetching accident by ID:', error);
		return null;
	}
};
