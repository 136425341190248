import { getProductsBySearch, getProductsPreview, getProductsRecientlyAdded } from './../api/productsApi';
import { useCallback, useEffect, useState } from 'react';
import { IProductPreview } from '../api/interfaces/interfaces';
import { Box } from '@mui/system';
import { InitialProductsView } from './InitialProductsView';
import { Footer } from '../components/footer/Footer';
import { UseGlobalContext } from '../provider/GlobalContext';
import { Card } from '@mui/material';
import banner from '../images/BannerRepuestArg.png';
import { ProductsRecientlyAdded } from '../components/products/recientlyAdded/ProductsRecientlyAdded';

export const Layout = () => {
	const [products, setProducts] = useState<IProductPreview[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingReciently, setLoadingReciently] = useState<boolean>(true);
	const [messageLayout, setMessageLayout] = useState<string>('Productos que pueden interesarte');
	const { searchedText, executeSearch, setSearchedText } = UseGlobalContext();
	const [cursor, setCursor] = useState<string | null>(null);
	const [hasMore, setHasMore] = useState(true);

	const loadProducts = useCallback(async (currentCursor: string | null) => {
		setLoading(true);
		try {
			const response = await getProductsPreview(currentCursor);

			setProducts((prev) => [...prev, ...response.products]);
			setCursor(response.nextCursor);
			setHasMore(response.hasMore);
		} catch (error) {
			console.error('Error loading products:', error);
		} finally {
			setLoading(false);
		}
	}, []);

	const [recientlyAddedProducts, setRecientlyAddedProducts] = useState<IProductPreview[]>([]);
	const isMobile = window.innerWidth <= 600;

	const loadRecientlyProducts = async () => {
		setLoadingReciently(true);
		try {
			const products = await getProductsRecientlyAdded();
			setRecientlyAddedProducts(products);
		} finally {
			setLoadingReciently(false);
		}
	};

	const loadMoreProducts = () => {
		if (hasMore && !loading) {
			loadProducts(cursor);
		}
	};

	const handleInputSearch = async () => {
		setMessageLayout('Repuestos basados en tu busqueda');
		setLoading(true);
		try {
			if (searchedText.length < 1) {
				// Reset to initial state if search is cleared
				setProducts([]);
				setCursor(null);
				setHasMore(true);
				await loadProducts(null);
			} else {
				setProducts([]);
				const prods = await getProductsBySearch(searchedText);
				setProducts(prods.products);
				setHasMore(false); // Disable infinite scroll for search results
			}
		} catch (error) {
			console.error('Search error:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadRecientlyProducts();
	}, []);

	// Carga inicial
	useEffect(() => {
		if (products.length === 0) {
			loadProducts(null);
		}
	}, [loadProducts]);

	useEffect(() => {
		if (executeSearch) {
			handleInputSearch();
		}
	}, [executeSearch]);

	useEffect(() => {
		setProducts([]);
		if(searchedText === 'cln'){
			setSearchedText('');
			loadProducts(null);
		}
	}, [executeSearch]);

	return (
		<>
			<Box sx={{ overflowY: 'hidden', display: 'flex' }}>
				<Card
					sx={{
						backgroundColor: '#111111',
						fontFamily: 'Roboto',
						fontWeight: 'bold',
						maxHeight: '29vh',
						width: '100vw',
						boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
					}}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
							padding: '10px',
							boxSizing: 'border-box',
							pl: { xs: 0, sm: 0, md: '10rem' },
							pr: { xs: 0, sm: 0, md: '10rem' },
						}}>
						<img
							src={banner}
							alt="banenr"
							style={{
								width: '100%', // Establece el ancho de la imagen al 100% del contenedor
								height: '100%', // Establece la altura de la imagen al 100% del contenedor
								// transform: 'scale(1)', // Aplica un efecto de zoom out (escala de 0.8 para reducir el tamaño)
								// transformOrigin: 'center', // Establece el punto de origen de la transformación en el centro de la imagen
							}}
						/>
					</Box>
				</Card>
			</Box>
			<ProductsRecientlyAdded products={recientlyAddedProducts} loading={loadingReciently} isMobile={isMobile} />
			<Box sx={{ display: 'inline', width: '100vw', height: '100vh' }}>
				<InitialProductsView
					productsData={products}
					loading={loading}
					messageLayout={messageLayout}
					loadMoreProducts={loadMoreProducts}
					hasMore={hasMore}
				/>
			</Box>
			<Footer />
		</>
	);
};
