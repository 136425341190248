import { Grid, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import { Accident, BillingMail } from '../../../../api/interfaces/interfaces';

const StatsBoxStyles = styled(Box)({
	backgroundColor: 'black',
	border: '1px solid #a70d15',
	padding: 20,
	borderRadius: 10,
	boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
	textAlign: 'center',
});

interface StatsBoxProps {
	billingList: Accident[];
}

export const StatsBox = (props: StatsBoxProps) => {
	const { billingList } = props;
	const cantCharged = billingList.filter((s)=> s.billingStatus === 'cobrado').length;
	const cantBilled = billingList.filter((s)=> s.billingStatus === 'facturado').length;
	return (
		<>
			<Grid item xs={12} sm={6}>
				<StatsBoxStyles>
					<Typography variant="h3" color="white">
						{cantBilled}
					</Typography>
					<Typography variant="body1" color="white">
						Siniestros facturados
					</Typography>
				</StatsBoxStyles>
			</Grid>
			<Grid item xs={12} sm={6}>
				<StatsBoxStyles>
					<Typography variant="h3" color="white">
						{cantCharged}
					</Typography>
					<Typography variant="body1" color="white">
						Siniestros cobrados
					</Typography>
				</StatsBoxStyles>
			</Grid>
		</>
	);
};
