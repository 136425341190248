import React, { useState, createRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';

interface DocumentsStepProps {
  handleFileChange: (files: File[], docReference: string, plateNumber: string | null) => void;
}

export const DocumentsStep: React.FC<DocumentsStepProps> = ({ handleFileChange }) => {
  const documentTypes = ['Orden de compra', 'Orden de reparacion', 'Factura', 'Vehiculo', 'Otros'];
  const [filePreviews, setFilePreviews] = useState<{ [key: string]: string }>({});

  const inputRefs = documentTypes.reduce((acc, docType) => {
    acc[docType] = createRef<HTMLInputElement>();
    return acc;
  }, {} as { [key: string]: React.RefObject<HTMLInputElement> });

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>, docType: string) => {
    const files = Array.from(e.target.files || []);
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilePreviews((prev) => ({ ...prev, [docType]: reader.result as string }));
        };
        reader.readAsDataURL(file);
      } else {
        setFilePreviews((prev) => ({ ...prev, [docType]: '' }));
      }
      handleFileChange(files, docType, null);
    }
  };

  return (
    <Grid container spacing={2}>
      {documentTypes.map((item) => (
        <Grid item xs={12} sm={4} key={item} >
          <Box>
            <Typography variant="caption" sx={{ color: 'white', mb: 1 }}>
              {item}
            </Typography>
            <input
              ref={inputRefs[item]}
              type="file"
              accept="image/*,.pdf,.doc,.docx"
              style={{ backgroundColor: '#333', padding: '4px', width: '100%' }}
              onChange={(e) => onFileChange(e, item)}
            />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              backgroundColor: 'black',
              padding: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '60px',
              mt: 1,
            }}
          >
            {filePreviews[item] && (
              <img
                src={filePreviews[item]}
                alt="Previsualización"
                style={{
                  width: '60px',
                  height: '60px',
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
