import { CreateInsuredData } from "../../../../../api/interfaces/interfaces";
import { Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import styled from '@emotion/styled';

interface AddressStepProps {
    formData: CreateInsuredData;
    errors: { [key: string]: string };
    handleTextFieldChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }

  
const StyledTextField = styled(TextField)(() => ({
    '& .MuiInputLabel-root': {
        color: 'white',
        '&.Mui-focused': {
            color: 'white',
        },
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'black',
        color: 'white',
        '& fieldset': {
            borderColor: '#9e1616',
        },
        '&:hover fieldset': {
            borderColor: '#9e1616',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#9e1616',
        },
    },
}));


export const AddressStep = (props: AddressStepProps) => {
    const { formData, errors, handleTextFieldChange} = props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StyledTextField
            fullWidth
            size="small"
            label="País"
            name="country"
            value={formData.country}
            onChange={handleTextFieldChange}
            error={!!errors.country}
            helperText={errors.country}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            fullWidth
            size="small"
            label="Provincia"
            name="province"
            value={formData.province}
            onChange={handleTextFieldChange}
            error={!!errors.province}
            helperText={errors.province}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            fullWidth
            size="small"
            label="Ciudad"
            name="city"
            value={formData.city}
            onChange={handleTextFieldChange}
            error={!!errors.city}
            helperText={errors.city}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            fullWidth
            size="small"
            label="Calle"
            name="street"
            value={formData.street}
            onChange={handleTextFieldChange}
            error={!!errors.street}
            helperText={errors.street}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            fullWidth
            size="small"
            label="Celular"
            name="cellphone"
            value={formData.cellphone}
            onChange={handleTextFieldChange}
            error={!!errors.cellphone}
            helperText={errors.cellphone}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            size="small"
            name="typeIVA"
            value={formData.typeIVA}
            onChange={(e: SelectChangeEvent<string>) => {
              const { name, value } = e.target;
              handleTextFieldChange({ target: { name: name!, value } } as any);
            }}
            displayEmpty
            error={!!errors.typeIVA}
            sx={{
              color: 'white',
              backgroundColor: 'black',
              border: '1px solid #9e1616',
              '.MuiSelect-icon': { color: 'white' },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: 'black',
                  '& .MuiMenuItem-root': {
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.3)' },
                  },
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              Tipo de IVA
            </MenuItem>
            <MenuItem value="Responsable Inscripto">Responsable Inscripto</MenuItem>
            <MenuItem value="Monotributista">Monotributista</MenuItem>
            <MenuItem value="Exento">Exento</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            fullWidth
            label="Código Postal"
            size="small"
            name="zipcode"
            value={formData.zipcode}
            onChange={handleTextFieldChange}
            error={!!errors.zipcode}
            helperText={errors.zipcode}
          />
        </Grid>
      </Grid>
    );
  };
  