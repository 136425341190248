import styled from '@emotion/styled';
import { Grid, Typography, Box } from '@mui/material'
import React from 'react'
import { Insured } from '../../../../api/interfaces/interfaces';


const StatsBoxStyles = styled(Box)({
    backgroundColor: 'black',
    border: '1px solid #a70d15',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
});

interface StatsBoxProps {
    // activeInsured: boolean[];
    insuredList: Insured[];
}

export const StatsBox = (props: StatsBoxProps) => {
    const { insuredList } = props;
    const activeInsuredCount = insuredList.map(a => a.status === "active").length;

    return (
        <>
            <Grid item xs={12} sm={6}>
                <StatsBoxStyles>
                    <Typography variant="h3" color="white">
                        {activeInsuredCount}
                    </Typography>
                    <Typography variant="body1" color="white"  >
                        Asegurados activos
                    </Typography>
                </StatsBoxStyles>
            </Grid>
            <Grid item xs={12} sm={6}>
                <StatsBoxStyles>
                    <Typography variant="h3" color="white">
                        {insuredList.length}
                    </Typography>
                    <Typography variant="body1" color="white" >
                        Asegurados totales
                    </Typography>
                </StatsBoxStyles>
            </Grid>
        </>
    )
}
