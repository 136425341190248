import { Box } from '@mui/system';

interface ManageInsuranceCompaniesProps {}

export const ManageInsuranceCompanies = (props: ManageInsuranceCompaniesProps) => {
	return (
		<Box
			sx={{
				border: '1px solid white',
				borderRadius: 2,
				p: 3,
				mx: 'auto',
				position: 'relative',
				minWidth: 750,
				maxWidth: 750,
			}}>
			Aca deberia ir todo el componente
		</Box>
	);
};
